import { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faPalette,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';

import SimpleButton from './SimpleButton';

type DropdownProps = {
  initialValue: string | null;
  options: { value: string; label: string }[];
  placeholder: string;
  icon: IconDefinition;
  onChange: (value: string) => void;
  label?: string;
};

const Dropdown = ({
  options,
  placeholder,
  label,
  onChange,
  initialValue,
}: DropdownProps) => {
  const [value, setValue] = useState<string | null>(initialValue);
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSelect = (option: { label: string; value: string }) => {
    setValue(option.label);
    setIsOpen(false);
    onChange(option.value);
  };

  // Handle keyboard navigation
  const handleKeyDown = (e: KeyboardEvent) => {
    if (!isOpen) return;

    switch (e.key) {
      case 'ArrowDown':
        setHighlightedIndex((prevIndex) => (prevIndex + 1) % options.length);
        break;
      case 'ArrowUp':
        setHighlightedIndex(
          (prevIndex) => (prevIndex - 1 + options.length) % options.length
        );
        break;
      case 'Enter':
        if (highlightedIndex >= 0 && highlightedIndex < options.length) {
          handleSelect(options[highlightedIndex]);
        }
        break;
      case 'Escape':
        setIsOpen(false);
        break;
    }
  };

  //Keyboard events
  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, highlightedIndex]);

  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      dropdownRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div className='pointer-events-auto relative z-[400] flex flex-col gap-4'>
      {/* LABEL */}
      {label && (
        <div className='text-d-text-negative font-jakarta text-sm text-d-text-2 excalidraw:pl-2'>
          {label}
        </div>
      )}
      <div
        className='relative w-full cursor-pointer rounded-xl bg-d-surface-bg-secondary text-sm font-medium'
        onClick={() => setIsOpen((prev) => !prev)}
        tabIndex={0}
        ref={dropdownRef}>
        {/* TRIGGER */}
        <div className='flex items-center justify-between rounded-xl p-1.5 font-jakarta text-d-text-neg'>
          <div className='flex items-center gap-4'>
            <SimpleButton
              leftIcon={faPalette}
              variant='tertiary'
              iconSize='16px'
              theme='flat'
              size='squareXS'></SimpleButton>
            <div className='font-medium text-d-text-neg'>
              {value ?? placeholder}
            </div>
          </div>
          <div className='pr-3 text-d-text-base duration-75'>
            {/*TODO : Retourner le chevron lorsque le dropdown est ouvert*/}
            <FontAwesomeIcon
              icon={faCaretDown}
              className='text-sm text-d-text-neg'
            />
          </div>
        </div>

        {/* DROPDOWN */}
        {isOpen && (
          <motion.div
            className='pointer-events-auto absolute bottom-16 z-[200] mt-2 flex w-full flex-col rounded-xl bg-d-surface-bg-tertiary p-2 excalidraw:bottom-auto excalidraw:top-full'
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}>
            {options.map(
              (option: { value: string; label: string }, index: number) => (
                <div
                  key={option.value}
                  className={`pointer-events-auto relative z-0 p-2`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelect(option);
                  }}
                  onMouseEnter={() => setHighlightedIndex(index)}>
                  {/* VALUE */}
                  <div className='text-text-base relative z-50 font-normal'>
                    {option.label}
                  </div>
                  {/* SELECTOR */}
                  {highlightedIndex === index && (
                    <motion.div
                      layout
                      layoutId='selector'
                      transition={{ duration: 0.1 }}
                      className='absolute inset-0 z-30 rounded-md bg-d-surface-bg-secondary text-d-text-base'></motion.div>
                  )}
                </div>
              )
            )}
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
