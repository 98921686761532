import { NavLink } from "react-router-dom";
import { BothLogo } from "../../logo/BothLogo";
import { CreatorCTA } from "./CreatorCTA";

import { FC } from "react";

type HeaderProps = {
  fnacStopped: boolean;
}

export const Header: FC<HeaderProps> = ({ fnacStopped }) => {
  return (
    <div className="flex justify-between items-center py-4 px-6 bg-fnac-dark-gray">
      <NavLink to="/">
        <BothLogo />
      </NavLink>
      <CreatorCTA fnacStopped={fnacStopped} />
    </div>
  );
};


