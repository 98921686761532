import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useTimeAgo = (date: Date) => {
  const [timeAgo, setTimeAgo] = useState('');
  const { i18n } = useTranslation();

  useEffect(() => {
    const rtf = new Intl.RelativeTimeFormat(i18n.language, {
      numeric: 'always',
    });

    const getTimeAgo = () => {
      const now = new Date();
      const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

      let value, unit: Intl.RelativeTimeFormatUnit;
      if (diffInSeconds < 60) {
        value = diffInSeconds;
        unit = 'second';
      } else if (diffInSeconds < 3600) {
        value = Math.floor(diffInSeconds / 60);
        unit = 'minute';
      } else if (diffInSeconds < 86400) {
        value = Math.floor(diffInSeconds / 3600);
        unit = 'hour';
      } else {
        value = Math.floor(diffInSeconds / 86400);
        unit = 'day';
      }

      setTimeAgo(rtf.format(-value, unit));
    };

    getTimeAgo();
    const intervalId = setInterval(getTimeAgo, 10000); // Update every minute

    return () => clearInterval(intervalId);
  }, [date, i18n.language]);

  return timeAgo;
};

export default useTimeAgo;
