import { useEffect, useState } from 'react';
import { FreshChallengerHeaderCard } from '../components/gallery/FreshChallengerHeaderCard';
import { ChallengerHeaderCard } from '../components/gallery/ChallengerHeaderCard';
import { Gallery } from '../components/gallery/Gallery';
// import { ThankModal } from "../components/modal/ThankModal";
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { ModeratorHeaderCard } from '../components/gallery/ModeratorHeaderCard';

// const SHOULD_SHOW_MODAL = true;

type HomePageProps = {
  isFnacModerator?: boolean;
  isFnacUser?: boolean;
  isFnacDemo?: boolean;
};

export const HomePage: React.FC<HomePageProps> = ({
  isFnacDemo = false,
  isFnacModerator = false,
}: HomePageProps) => {
  const { t } = useTranslation(['fnac']);
  const { keycloak } = useKeycloak();

  const [hasAlreadySubmitted, setHasAlreadySubmitted] =
    useState<boolean>(false);
  const [shouldResfreshStats, setShouldRefreshStats] = useState<number>(
    Date.now()
  );
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!keycloak.token) return;

    fetch(`${import.meta.env.VITE_API_URL}/event/fnac70/challenger-info`, {
      headers: { authorization: `Bearer ${keycloak.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) return;

        setHasAlreadySubmitted(data.hasAlreadySubmitted);
      })
      .catch((err) => {
        console.error('Oups, An error has occurred', err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [keycloak]);

  const handlePropagateRefresh = () => {
    setShouldRefreshStats(Date.now());
  };

  if (loading) return;

  return (
    <div className='flex h-full w-full flex-col overflow-auto bg-fnac-black'>
      {isFnacModerator ? (
        <ModeratorHeaderCard shouldResfreshStats={shouldResfreshStats} />
      ) : hasAlreadySubmitted ? (
        <ChallengerHeaderCard />
      ) : (
        <FreshChallengerHeaderCard />
      )}
      <Gallery
        title={t('fnac:Gallery.Title')}
        isFnacModerator={isFnacModerator}
        handlePropagateRefresh={handlePropagateRefresh}
        isFnacDemo={isFnacDemo}
      />
    </div>
  );
};
