import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fabric } from 'fabric';
import { useTranslation } from 'react-i18next';

type ShareBoxProps = {
  imageSrc: string;
};

export const ShareBox: React.FC<ShareBoxProps> = ({ imageSrc }) => {
  const { t } = useTranslation(['fnac']);
  const downloadImage = () => {
    const footerImgUrl = '/beink-footer.png';

    // Load footer image with logo
    fabric.Image.fromURL(footerImgUrl, (footerImg) => {
      // Load image to download
      fabric.Image.fromURL(
        imageSrc,
        (img) => {
          // Combine the image and the footer
          const group = new fabric.Group([img]);
          const bounds = group.getBoundingRect();
          footerImg.scale(0.8).set({
            top: bounds.top + bounds.height + 10,
            left: bounds.left + 10,
          });
          group.addWithUpdate(footerImg);

          // Add a white background
          const groupBoundingRect = group.getBoundingRect();
          const background = new fabric.Rect({
            top: groupBoundingRect.top,
            left: groupBoundingRect.left,
            width: groupBoundingRect.width,
            height: groupBoundingRect.height,
            fill: '#fff',
          });
          group.addWithUpdate(background);
          background.sendToBack();

          // Export as URL
          const dataImg = group.toDataURL({});

          // Create a <a href> tag and click on it
          const a = document.createElement('a');
          a.href = dataImg;
          a.download = `BEINK_DREAM_my-fnac-card.jpg`;
          a.click();
        },
        {
          crossOrigin: 'Anonymous',
        }
      );
    });
  };

  return (
    <div
      className={`flex max-w-[300px] flex-col justify-center gap-3 rounded-3xl border border-hidden bg-grey-400 p-4 sm:p-4 md:p-6`}>
      <p className='space-x-2 text-center text-lg uppercase'>
        <span className='font-ccsign font-bold text-white'>
          {t('fnac:MyContest.Share')}
        </span>
        <span className={`font-jakarta font-bold text-fnac-mustard`}>
          {t('fnac:MyContest.MyDesign')}
        </span>
      </p>
      <div className='text-center text-sm'>
        <p>
          Vous pouvez télécharger l'image et la partager depuis votre galerie
        </p>
      </div>

      <button
        className={`mt-2 flex items-center justify-center space-x-2 rounded-xl border border-hidden bg-white p-4 transition-transform duration-75 hover:scale-[102%]`}
        onClick={downloadImage}>
        <FontAwesomeIcon
          className='text-fnac-black'
          icon={faFileDownload}></FontAwesomeIcon>
        <span className='font-jakarta text-sm font-bold text-fnac-black'>
          {t('fnac:MyContest.Download')}
        </span>
      </button>
    </div>
  );
};
