import { Link } from 'react-router-dom';

const NonAdherentModal = () => {
  return (
    <div className='fixed inset-0 z-100 flex items-center justify-center border border-hidden bg-fnac-gray bg-opacity-80 p-4 font-jakarta font-extrabold backdrop-blur-sm'>
      <div className='beink-radial-gradient w-full max-w-3xl rounded-3xl px-24 py-16'>
        <div className='flex flex-col items-center gap-6 text-center'>
          <h2 className='text-2xl uppercase'>
            <div>Concours réservé</div>
            <div>aux adhérents fnac+</div>
          </h2>
          <img
            className='h-40 max-h-40 min-h-40 w-64 min-w-64 max-w-64'
            src='/v2/non-adherent.png'
          />

          <div className='flex flex-col gap-3 font-normal'>
            <span>
              Pour participer au concours et imaginer la carte Fnac+ édition
              spéciale 70 ans,
              <a
                className='mx-1 ml-1 text-fnac-mustard underline'
                target='blank'
                href='https://www.fnac.com/choisir-carte'>
                il vous suffit d’adhérer à Fnac+
              </a>
              <span> et se reconnecter à Beink Dream.</span>
            </span>
            <span>
              En attendant, vous pouvez voter pour vos créations préférées.
            </span>
          </div>

          <Link to={'/gallery'}>
            <button className='rounded-md bg-fnac-mustard px-8 py-4 font-jakarta text-fnac-black'>
              Aller à la galerie de votes
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NonAdherentModal;
