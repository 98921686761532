import { fabric } from 'fabric';
import { IEvent } from 'fabric/fabric-impl';
import { FabricJSEditor } from 'fabricjs-react';
import { useEffect, useRef, type ChangeEvent, type DragEvent } from 'react';
import { isTouchpad } from '../helpers/device';
import { PenSize, EraserSize, type Tools } from '../types';

export const useEditorActions = ({
  editor,
  tool,
  setTool,
  setColor,
  setPenSize,
  setEraserSize,
  selectedObjects,
  setZoom,
  setIsDragOver,
  historyUndo,
  setHistoryUndo,
  historyRedo,
  setHistoryRedo,
  clipboard,
  setClipboard,
}: {
  editor?: FabricJSEditor;
  tool: Tools;
  setTool: React.Dispatch<React.SetStateAction<Tools>>;
  setColor: React.Dispatch<React.SetStateAction<string>>;
  setPenSize: React.Dispatch<React.SetStateAction<PenSize>>;
  setEraserSize: React.Dispatch<React.SetStateAction<EraserSize>>;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
  selectedObjects: fabric.Object[] | undefined;
  setIsDragOver: React.Dispatch<React.SetStateAction<boolean>>;
  historyUndo: string[];
  setHistoryUndo: React.Dispatch<React.SetStateAction<string[]>>;
  historyRedo: string[];
  setHistoryRedo: React.Dispatch<React.SetStateAction<string[]>>;
  clipboard: fabric.Object[] | null;
  setClipboard: React.Dispatch<React.SetStateAction<fabric.Object[] | null>>;
}) => {
  const toolRef = useRef(tool);
  useEffect(() => {
    toolRef.current = tool;
  }, [tool]);

  // Helper
  const getCenterView = (): { top: number; left: number } => {
    if (!editor?.canvas) return { top: 0, left: 0 };
    const center = editor.canvas.getVpCenter();
    // center minus the Beink panel offest
    const left = center.x - 200;
    const top = center.y - 80;
    return { top, left };
  };

  const selectAllObjects = () => {
    if (!editor?.canvas) return;
    editor.canvas.discardActiveObject();
    const sel = new fabric.ActiveSelection(editor.canvas.getObjects(), {
      canvas: editor.canvas,
    });
    editor.canvas.setActiveObject(sel);
    editor.canvas.requestRenderAll();
  };

  const bringSelectionToFront = () => {
    if (!selectedObjects?.length || !editor) return;
    for (const item of selectedObjects) editor.canvas.bringToFront(item);
    editor.canvas.requestRenderAll();
  };

  const bringSelectionToBack = () => {
    if (!selectedObjects?.length || !editor) return;
    for (const item of selectedObjects) editor.canvas.sendToBack(item);
    editor.canvas.requestRenderAll();
  };

  const lockCanvas = () => {
    if (!editor) return;

    editor.canvas.selection = false;
    editor.canvas.interactive = false;
    toggleLockObjects(true);

    if (editor.canvas.isDrawingMode) {
      cancelLastDraw();
      editor.canvas.isDrawingMode = false;
    }
  };

  const cancelLastDraw = () => {
    if (!editor) return;
    const evt = new TouchEvent('touchend', {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (editor.canvas as any).upperCanvasEl.dispatchEvent(evt);
    const lastObject = editor.canvas.getObjects().pop();
    if (lastObject) editor.canvas.remove(lastObject);
    editor.canvas.renderAll();
  };

  // UNDO / REDO -> canvas object only for now

  let historyProcessing = true;

  setTimeout(() => {
    historyProcessing = false;
  }, 350);

  const historySave = async () => {
    if (!editor || historyProcessing || tool === 'crop') return;
    const json = JSON.stringify(editor?.canvas.toJSON());

    if (historyUndo[historyUndo.length - 1] != json) {
      setHistoryUndo([...historyUndo, json]);
      if (historyUndo.length && historyRedo.length) setHistoryRedo([]);
    }
  };

  const undoAction = async () => {
    if (!editor || !historyUndo.length) return;
    historyProcessing = true;
    const h = [...historyUndo];
    const current = h.pop();
    const history = [...h].pop();
    setHistoryUndo(h);

    if (current) {
      setHistoryRedo([...historyRedo, current]);
      if (history) await loadHistory(history);
      else clear();
    } else {
      historyProcessing = false;
    }
  };

  const redoAction = async () => {
    if (!editor || !historyRedo.length) return;
    historyProcessing = true;
    const h = [...historyRedo];
    const next = h.pop();
    setHistoryRedo(h);

    if (next) {
      setHistoryUndo([...historyUndo, next]);
      await loadHistory(next);
    } else historyProcessing = false;
    historySave();
  };

  const loadHistory = async (history: string) => {
    if (!editor || !history) return;
    return editor.canvas.loadFromJSON(history, () => {
      historyProcessing = false;
      editor.canvas.renderAll();
    });
  };

  // COPY and PASTE from/to canvas

  const copyObject = () => {
    if (selectedObjects?.length) setClipboard(selectedObjects);
    else setClipboard(null);
  };

  const pasteHandler = async () => {
    if (clipboard !== null) pasteObject();
    else pasteImage();
  };

  // Paste handle for Fnac: import image is not allowed
  const pasteHandlerWithoutImport = async () => {
    if (clipboard !== null) pasteObject();
  };

  const pasteObject = () => {
    if (!editor?.canvas || clipboard === null) return;

    editor.canvas.discardActiveObject();
    const clones: fabric.Object[] = [];

    clipboard.forEach((object: fabric.Object) => {
      object.clone((cloned: fabric.Object) => {
        if (cloned.left && cloned.top) {
          cloned.left += 150;
          cloned.top += 100;
        }
        editor.canvas.add(cloned);
        clones.push(cloned);
      });
    });
    editor.canvas.renderAll();

    // Select clones
    setTimeout(() => {
      const selection = new fabric.ActiveSelection(clones, {
        canvas: editor.canvas,
      });
      editor.canvas.setActiveObject(selection);
      editor.canvas.requestRenderAll();
    }, 100);
    setClipboard(null);
  };

  const pasteImage = async () => {
    try {
      const clipboardContents = await navigator.clipboard.read();
      for (const item of clipboardContents) {
        if (!item.types.includes('image/png')) {
          throw new Error('Clipboard does not contain PNG image data.');
        }
        const blob = await item.getType('image/png');
        if (!blob) continue;
        const url = URL.createObjectURL(blob);
        importImage(url);
      }
    } catch (error: unknown) {
      console.error(error);
    }
  };

  // Image manipulation (import, export, crop, ...)

  const uploadImage = (e: ChangeEvent<HTMLInputElement>) => {
    if (!editor || !e.target.files || !e.target.files[0]) return;
    const fileType = e.target.files[0].type;
    const url = URL.createObjectURL(e.target.files[0]);

    if (['image/png', 'image/jpg', 'image/jpeg'].includes(fileType)) {
      importImage(url);
    } else if (fileType === 'image/svg+xml') {
      fabric.loadSVGFromURL(url, (objects, options) => {
        const svg = fabric.util.groupSVGElements(objects, options);
        svg.scaleToWidth(180);
        svg.scaleToHeight(180);
        editor.canvas.add(svg);
      });
    }
  };

  const dropImage = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.items) {
      [...event.dataTransfer.items].forEach((item) => {
        if (
          item.kind === 'file' &&
          ['image/png', 'image/jpg', 'image/jpeg'].includes(item.type)
        ) {
          const file = item.getAsFile();
          if (file) {
            const url = URL.createObjectURL(file);
            importImage(url);
          }
        }
      });
    }
    setIsDragOver(false);
  };

  const importImageFromElement = (elementId: string) => {
    if (!editor?.canvas || !elementId) return;

    const imgElement = document.getElementById(elementId) as HTMLImageElement;
    if (!imgElement) return;

    const img = new fabric.Image(imgElement);

    if (!img.width || !img.height) return;

    const maxEdgeSize = Math.max(1024, img.width, img.height);
    const scale = 1024 / maxEdgeSize;

    const { top, left } = getCenterView();

    // CropClip is the actual clipath of the image
    const cropClip = new fabric.Rect({
      top: 0,
      left: 0,
      width: img.width,
      height: img.height,
      originX: 'center',
      originY: 'center',
    });

    img.scale(scale).set({
      left,
      top,
      originX: 'center',
      originY: 'center',
      clipPath: cropClip,
    });

    editor.canvas.add(img).renderAll();
    changeTool('select');
    editor.canvas.setActiveObject(img);

    historyProcessing = false;
    historySave();
  };

  const importImage = (url: string) => {
    if (!editor?.canvas || !url) return;
    fabric.Image.fromURL(
      url,
      (img) => {
        if (!img.width || !img.height) return;

        const maxEdgeSize = Math.max(1024, img.width, img.height);
        const scale = 1024 / maxEdgeSize;

        const { top, left } = getCenterView();

        // CropClip is the actual clipath of the image
        const cropClip = new fabric.Rect({
          top: 0,
          left: 0,
          width: img.width,
          height: img.height,
          originX: 'center',
          originY: 'center',
        });

        img.scale(scale).set({
          left,
          top,
          originX: 'center',
          originY: 'center',
          clipPath: cropClip,
        });

        editor.canvas.add(img).renderAll();
        changeTool('select');
        editor.canvas.setActiveObject(img);

        historyProcessing = false;
        historySave();
      },
      {
        crossOrigin: 'Anonymous',
      }
    );
  };

  // TODO @Aurel: Rework exportImage/exportImageAndMasksOverlay to handle both cases and remove duplicate code
  const exportImage = async (logo?: fabric.Object): Promise<string> => {
    if (!editor?.canvas || !selectedObjects || !selectedObjects.length)
      return '';

    const groupElements = new fabric.Group(selectedObjects);

    if (logo) {
      const tmpBounds = groupElements.getBoundingRect();
      logo.scale(0.8).set({
        top: tmpBounds.top + tmpBounds.height + 10,
        left: tmpBounds.left + 10,
      });
      groupElements.addWithUpdate(logo);
    }

    // Add a white background to the selected objects and remove it after
    const groupBoundingRect = groupElements.getBoundingRect();
    const background = new fabric.Rect({
      top: groupBoundingRect.top,
      left: groupBoundingRect.left,
      width: groupBoundingRect.width,
      height: groupBoundingRect.height,
      fill: '#fff',
    });
    groupElements.addWithUpdate(background);
    background.sendToBack();

    editor.canvas.add(groupElements);
    const dataImg = groupElements.toDataURL({});

    editor.canvas.discardActiveObject();

    // Remove background and group
    editor.canvas.remove(groupElements);
    groupElements.destroy();
    editor.canvas.requestRenderAll();

    return dataImg;
  };

  // TODO @Aurel: Rework exportImage/exportImageAndMasksOverlay to handle both cases and remove duplicate code
  const exportImageAndMasksOverlay = async (
    logo?: fabric.Object
  ): Promise<string[]> => {
    if (!editor?.canvas || !selectedObjects || !selectedObjects.length)
      return [];

    const selectedImages = selectedObjects.filter(
      (o: fabric.Object) => o.type === 'image'
    );

    const selectedMasks = selectedObjects
      .filter((o: fabric.Object) => o.type === 'path')
      .map((o: fabric.Object) => {
        let cloned = new fabric.Object();
        o.clone((clone: fabric.Object) => {
          clone.set({
            stroke: '#fff',
          });
          clone.set({ fill: '#fff' });
          cloned = clone;
        });
        return cloned;
      });

    const groupElements = new fabric.Group(selectedImages);

    if (logo) {
      const tmpBounds = groupElements.getBoundingRect();
      logo.scale(0.8).set({
        top: tmpBounds.top + tmpBounds.height + 10,
        left: tmpBounds.left + 10,
      });
      groupElements.addWithUpdate(logo);
    }

    // Add a white background to the selected objects and remove it after
    const groupBoundingRect = groupElements.getBoundingRect();
    const background = new fabric.Rect({
      top: groupBoundingRect.top,
      left: groupBoundingRect.left,
      width: groupBoundingRect.width,
      height: groupBoundingRect.height,
      fill: '#fff',
    });
    groupElements.addWithUpdate(background);
    background.sendToBack();

    editor.canvas.add(groupElements);
    const dataImg = groupElements.toDataURL({
      width: groupBoundingRect.width,
      height: groupBoundingRect.height,
    });

    const groupMasks = new fabric.Group(selectedMasks);
    // From the groupElements Size and Position : Add a black background to the groupMasks
    const masksBackground = new fabric.Rect({
      top: groupBoundingRect.top,
      left: groupBoundingRect.left,
      width: groupBoundingRect.width,
      height: groupBoundingRect.height,
      fill: '#000',
    });
    const masksClipPath = new fabric.Rect({
      top: groupBoundingRect.top,
      left: groupBoundingRect.left,
      width: groupBoundingRect.width,
      height: groupBoundingRect.height,
    });

    groupMasks.addWithUpdate(masksBackground);
    masksBackground.sendToBack();

    groupMasks.clipPath = masksClipPath;
    editor.canvas.add(groupMasks);

    // export to an image by cropping the object's padding
    const maskImg = groupMasks.toDataURL({
      top: (masksClipPath.top ?? 0) - (groupMasks.top ?? 0),
      left: (masksClipPath.left ?? 0) - (groupMasks.left ?? 0),
      width: masksClipPath.width,
      height: masksClipPath.height,
    });

    editor.canvas.discardActiveObject();

    // Remove background and group
    editor.canvas.remove(groupElements);
    editor.canvas.remove(groupMasks);
    groupElements.destroy();
    groupMasks.destroy();
    editor.canvas.requestRenderAll();

    return [dataImg, maskImg];
  };

  const downloadImage = () => {
    if (!editor) return;

    let footerImgUrl = '/beink-footer.png';

    // Vivatech 2024 footer logo
    const today = new Date().getTime();
    const vivatechStart = new Date('05/19/2024').getTime();
    const vivatechEnd = new Date('05/27/2024').getTime();
    if (today > vivatechStart && today < vivatechEnd)
      footerImgUrl = '/beink-footer-vivatech.png';

    // Add the Beink Logo
    fabric.Image.fromURL(
      footerImgUrl,
      (img) => {
        exportImage(img).then((imgData) => {
          if (!imgData) return;

          const a = document.createElement('a');
          const date = new Date().toLocaleDateString().replaceAll('/', '');
          const time = new Date().toLocaleTimeString().replaceAll(':', '');

          a.href = imgData;
          a.download = `BEINK_DREAM-${date}_${time}.jpg`;
          a.click();
        });
      },
      {
        crossOrigin: 'Anonymous',
      }
    );
  };

  const cropImage = () => {
    if (
      !editor?.canvas ||
      selectedObjects?.length !== 1 ||
      selectedObjects[0].get('type') !== 'image'
    )
      return;

    const target = selectedObjects[0];
    const cropClip = target.clipPath;
    if (!target || !cropClip) return;

    // CropClip is set to relative when cropZOne is not manipulate
    const setClipRelative = () => {
      if (!cropClip.absolutePositioned || !target.width || !target.height)
        return;

      const imgCoords = target.calcTransformMatrix();
      const clipPos = cropClip.getCenterPoint();

      cropClip.set({
        absolutePositioned: false,
        top: (clipPos.y - imgCoords[5]) / imgCoords[3],
        left: (clipPos.x - imgCoords[4]) / imgCoords[0],
        width: target.width,
        height: target.height,
        angle: 0,
        dirty: true,
      });
    };

    const setClipAbsolute = () => {
      if (cropClip.absolutePositioned || !target.width || !target.height)
        return;

      const imgPos = target.getCenterPoint();
      const clipPos = cropClip.getCenterPoint();

      const newWidth = target.width * (target.scaleX ?? 1);
      const newHeight = target.height * (target.scaleY ?? 1);

      cropClip.set({
        absolutePositioned: true,
        top: imgPos.y + clipPos.y * (target.scaleY ?? 1),
        left: imgPos.x + clipPos.x * (target.scaleX ?? 1),
        width: newWidth,
        height: newHeight,
        angle: target.angle,
        dirty: true,
      });
    };

    setClipAbsolute();
    target.selectable = false;
    target.hasControls = false;

    // CropZone is the rectangle used for user manipulation
    const cropZone = new fabric.Rect({
      fill: '',
      stroke: '#222',
      strokeWidth: 1,
      strokeDashArray: [6, 6],
      strokeUniform: true,
      top: cropClip.top,
      left: cropClip.left,
      width: cropClip.width,
      height: cropClip.height,
      originX: 'center',
      originY: 'center',
      scaleX: cropClip.scaleX,
      scaleY: cropClip.scaleY,
      lockRotation: true,
      cornerColor: '#333',
      transparentCorners: true,
      angle: target.angle,
      padding: -2,
    });
    cropZone.setControlsVisibility({ mtr: false });

    // Fade clone of image shown when Cropping
    let imgFade: fabric.Object;
    target.clone((clone: fabric.Object) => {
      clone.set({
        opacity: 0.125,
        lockRotation: true,
        lockMovementX: true,
        lockMovementY: true,
        selectable: false,
        hasControls: false,
        clipPath: undefined,
      });
      editor.canvas.add(clone);
      clone.sendBackwards();
      imgFade = clone;
    });

    const cropState = {
      top: cropZone.top ?? 0,
      left: cropZone.left ?? 0,
      scaleX: cropZone.scaleX,
      scaleY: cropZone.scaleY,
    };

    // Reset last good CropZone position - if out on img bounds
    const resetCropZone = () => {
      cropZone.set({
        left: cropState.left,
        top: cropState.top,
        scaleX: cropState.scaleX,
        scaleY: cropState.scaleY,
      });
    };

    // Copy State of cropZone to CropClip
    const copyZoneToClip = () => {
      cropClip.setPositionByOrigin(
        cropZone.getCenterPoint(),
        'center',
        'center'
      );
      cropClip.set({
        scaleX: cropZone.scaleX,
        scaleY: cropZone.scaleY,
      });
    };

    // CROPZONE Events : update crop when the CropZone is edited by user

    const cropUpdate = () => {
      cropZone.setCoords();

      // Test if cropZone is in the image
      if (!cropZone.isContainedWithinObject(target)) {
        resetCropZone();
      } else {
        cropState.top = cropZone.top ?? 0;
        cropState.left = cropZone.left ?? 0;
        cropState.scaleX = cropZone.scaleX ?? 1;
        cropState.scaleY = cropZone.scaleY ?? 1;
      }

      copyZoneToClip();
    };

    const endCrop = () => {
      setClipRelative();
      target.selectable = true;
      target.hasControls = true;
      editor.canvas.remove(cropZone, imgFade);
      editor.canvas.renderAll();
      changeTool('select');
    };

    cropZone.on('moving', cropUpdate);
    cropZone.on('scaling', cropUpdate);
    cropZone.on('deselected', endCrop);

    setTool('crop');
    editor.canvas.add(cropZone);
    editor.canvas.setActiveObject(cropZone);
    editor.canvas.bringToFront(cropZone);
    editor.canvas.renderAll();
  };

  // TOOLS functions
  const changeTool = (tool: Tools) => {
    if (!editor) return;
    editor.canvas.hoverCursor = 'move';
    editor.canvas.interactive = true;
    editor.canvas.selection = false;
    editor.canvas.isDrawingMode = false;

    switch (tool) {
      case 'pen':
        penTool();
        break;
      case 'eraser':
        eraserTool();
        break;
      case 'select':
        selectTool();
        break;
      case 'crop':
        if (selectedObjects?.length === 1) cropImage();
        else changeTool('select');
        break;
      case 'hand':
        handTool();
        break;
      case 'text':
        textTool();
        break;
      case 'eyedropper':
        eyedropperTool();
        break;
    }

    toggleLockObjects(['eyedropper', 'hand', 'colorPick'].includes(tool));
    if (tool !== 'crop') editor.canvas.discardActiveObject();
    editor.canvas.requestRenderAll();
  };

  const handTool = () => {
    if (!editor) return;
    editor.canvas.defaultCursor = 'grab';
    editor.canvas.hoverCursor = 'grab';
    editor.canvas.interactive = false;
    setTool('hand');
  };

  const penTool = () => {
    if (!editor) return;
    editor.canvas.defaultCursor = 'crosshair';
    editor.canvas.isDrawingMode = true;
    editor.canvas.freeDrawingBrush = new fabric.PencilBrush(editor.canvas);
    changePenSize(PenSize.md);
    setTool('pen');
  };

  const eraserTool = () => {
    if (!editor) return;
    editor.canvas.defaultCursor = 'crosshair';
    editor.canvas.isDrawingMode = true;
    // @ts-ignore
    editor.canvas.freeDrawingBrush = new fabric.EraserBrush(editor.canvas);
    changeEraserSize(EraserSize.md);
    setTool('eraser');
  };

  const selectTool = () => {
    if (!editor) return;
    editor.canvas.defaultCursor = 'default';
    editor.canvas.selection = true;
    setTool('select');
  };

  const textTool = () => {
    if (!editor) return;
    editor.canvas.defaultCursor = 'text';
    setTool('text');
  };

  const toggleLockObjects = (lock: boolean) => {
    const objects = editor?.canvas.getObjects() ?? [];
    for (const item of objects) {
      item.setCoords();
      if (!item.visible) continue;
      item.lockMovementX = lock;
      item.lockMovementY = lock;
      item.lockScalingX = lock;
      item.lockScalingY = lock;
      item.lockSkewingX = lock;
      item.lockSkewingY = lock;
      item.lockRotation = lock;
      item.hasControls = !lock;
      item.selectable = !lock;
    }
  };

  const eyedropperTool = () => {
    if (!editor) return;
    editor.canvas.defaultCursor = 'crosshair';
    editor.canvas.hoverCursor = 'crosshair';
    setTool('eyedropper');
  };

  const eyedropperAction = (pointer: { x: number; y: number }) => {
    if (!editor) return;

    const imageData = editor.canvas
      .getContext()
      .getImageData(pointer.x, pointer.y, 1, 1).data;
    const color = new fabric.Color(
      `rgb(${imageData[0]}, ${imageData[1]}, ${imageData[2]})`
    );
    if (color && imageData[3] !== 0) changeColor('#' + color.toHex());
  };

  const changeColor = (c: string) => {
    if (!editor) return;
    editor.canvas.freeDrawingBrush.color = c;
    setColor(c);
  };

  const changePenSize = (size: PenSize) => {
    if (!editor) return;
    editor.canvas.freeDrawingBrush.width = size;
    setPenSize(size);
  };

  const changeEraserSize = (size: EraserSize) => {
    if (!editor) return;
    editor.canvas.freeDrawingBrush.width = size;
    setEraserSize(size);
  };

  const clear = () => {
    if (!editor) return;
    editor.canvas._objects.splice(0, editor.canvas._objects.length);
    editor.canvas.discardActiveObject();
    editor.canvas.renderAll();
  };

  const removeSelectedObject = () => {
    if (!editor || !selectedObjects?.length) return;
    editor.canvas.discardActiveObject();
    editor.canvas.remove(...selectedObjects);
    editor.canvas.renderAll();
  };

  const addText = (opt: IEvent<Event>) => {
    if (!editor || (opt.target && opt.target.type === 'textbox')) return;
    selectTool();

    const pointer = editor.canvas.getPointer(opt.e);
    const newText = new fabric.Textbox('hello world', {
      top: pointer.y,
      left: pointer.x,
    });
    editor.canvas.add(newText);
    editor.canvas.setActiveObject(newText);
    newText.enterEditing();
  };

  // Editor Zoom and Pan

  const zoomEditor = (evt: WheelEvent) => {
    if (!editor) return;
    let newZoom = editor?.canvas.getZoom();
    if (isTouchpad(evt)) newZoom *= 0.999 ** (evt.deltaY * 4);
    else newZoom *= 0.999 ** evt.deltaY;
    canvasZoom(newZoom, evt.offsetX, evt.offsetY);
  };

  const canvasZoom = (newZoom: number, x: number, y: number) => {
    if (!editor) return;

    const minZoom = 0.25;
    const maxZoom = 2;

    if (newZoom > maxZoom) newZoom = maxZoom;
    if (newZoom < minZoom) newZoom = minZoom;
    editor.canvas.zoomToPoint({ x, y }, newZoom);
    editor.canvas.requestRenderAll();
    setZoom(newZoom);
  };

  const panEditor = (translateX: number, translateY: number) => {
    if (!editor || !editor.canvas.viewportTransform) return;
    editor.canvas.viewportTransform[4] += translateX;
    editor.canvas.viewportTransform[5] += translateY;
    editor.canvas.requestRenderAll();
  };

  const resetZoom = async () => {
    if (!editor?.canvas.width || !editor.canvas.height) return;

    const center = new fabric.Point(
      editor.canvas.width / 2,
      editor.canvas.height / 2
    );
    editor.canvas.zoomToPoint(center, 0.5);
    editor.canvas.requestRenderAll();
    setZoom(0.5);
  };

  return {
    cropImage,
    historySave,
    selectAllObjects,
    bringSelectionToFront,
    bringSelectionToBack,
    changeColor,
    clear,
    removeSelectedObject,
    addText,
    resetZoom,
    undoAction,
    redoAction,
    uploadImage,
    dropImage,
    importImage,
    importImageFromElement,
    exportImage,
    exportImageAndMasksOverlay,
    downloadImage,
    changeTool,
    handTool,
    penTool,
    selectTool,
    textTool,
    toggleLockObjects,
    eyedropperAction,
    changePenSize,
    changeEraserSize,
    zoomEditor,
    panEditor,
    lockCanvas,
    canvasZoom,
    copyObject,
    pasteHandler,
    pasteHandlerWithoutImport, // Fnac only
  };
};
