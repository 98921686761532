import ResultsGenerationImage from './ResultsGenerationImage';
import { useStore } from '@nanostores/react';
import { $generations } from '../../../../store/generations';
import { usePanelContext } from '../context/usePanelContext';

type LastGenerationProps = {
  isPanelOpen: boolean;
};

const LastGeneration = ({ isPanelOpen }: LastGenerationProps) => {
  const generations = useStore($generations);
  const { loading } = usePanelContext();
  const firstGeneration = generations[0];

  return (
    <>
      {firstGeneration && (
        <div
          className={`grid w-full justify-center gap-2 ${
            isPanelOpen ? 'grid-cols-4' : 'flex flex-col'
          }`}>
          {firstGeneration.urls.map((url, index) => (
            <div
              key={index}
              className='aspect-square cursor-pointer overflow-hidden rounded-lg bg-d-surface-bg-secondary'>
              <ResultsGenerationImage
                imagePath={url}
                isLoading={loading}
                className='h-full w-full object-cover'
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default LastGeneration;
