import React from "react";
import { LittleStampShape } from "./LittleStampShape";

// No translation for the moment as this page is dedicated to Fnac Contest and they ask for French only.
// Moreover, we can't change the langage from this page (by design)
export const FnacClaim: React.FC = () => {
  return (
    <div className="grid  w-full order-last md:order-first md:grid-rows-[1fr_1fr] h-full  max-w-[600px] lg:max-w-[900px] ">
      <div className="h-full hidden md:block small-h:hidden bg-no-repeat bg-contain bg-[url('/v2/kv_top.png')]"></div>
      <div className="h-full flex flex-col p-8 lg:p-16 gap-2 text-white justify-between ">
        <div className="text-2xl xl:text-3xl">
          <LittleStampShape label={"Concours"} />

          <div className="font-jakarta font-extrabold uppercase  ">
            L’IA au service de votre créativité
          </div>

          <div className="font-ccsign font-bold   text-fnac-mustard">
            Et si vous deveniez l'artiste de la carte fnac ?
          </div>
          <div className="font-jakarta  text-white">
            Vous pourrez gagner un des nombreux lots Samsung et votre visuel imprimé sur la prochaine carte Fnac+ édition spéciale 70 ans !
          </div>
        </div>

        <div className="grid grid-cols-[1fr_max-content] ">
          <div className="flex flex-col gap-2">
            <div className="uppercase font-bold">En partenariat avec</div>
            <div className="flex gap-6 justify-start">
              <img className="w-[100px] xl:w-[200px]" src="/v2/beink-logo.svg" />
              <img className="w-[90px] xl:w-[200px]" src="/v2/samsung.svg" alt="" />
            </div>
            <span className="text-[12px]">Concours réservé aux adhérents Fnac+ et One, accessible jusqu'au 31 octobre.</span>
            <span className="text-[12px]">
              Pour consulter le règlement du jeu concours, <a href="https://www.fnac.com/concours-carte-IA" target="_blank" className="underline">cliquez ici</a>. </span>
          </div>
          <img className="hidden sm:block xl:w-[120px]" src="/v2/fnac-logo.png" />
        </div>
      </div>
    </div>
  );
};
