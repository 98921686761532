import { useKeycloak } from "@react-keycloak/web";
import { NavLink } from "react-router-dom";
import i18next, { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import { UserRole } from "../types";
import {
  Home,
  UserRound,
  Sparkles,
  LogOut,
  Languages,
  ReceiptText,
  Users,
} from "lucide-react";
import { stripeEnabled, teamManagementEnabled } from "../helpers/FeatureFlags";

const languages = ["fr", "en"] as const;

const Nav = () => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation(["translation"]);

  const userRoles: string[] = keycloak.realmAccess?.roles || [];
  const isSuscriber = userRoles.includes(UserRole.Subscriber_1);
  const isTeamManager = userRoles.includes(UserRole.TeamManager);
  const changeLang = () => {
    if (i18next.language === "fr") changeLanguage("en");
    else changeLanguage("fr");
  };

  return (
    <aside className="bg-grey h-full flex flex-col justify-between md:w-64 w-14 pt-28 font-medium fixed top-0">
      <nav>
        <div className="p-6 pb-2 hidden md:block text-primary text-sm uppercase">
          {t("Home.NavTitle.Profile")}
        </div>

        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive
              ? "md:p-6 p-4 gradient-btn-opacity w-full flex items-center gap-3"
              : "md:p-6 p-4 w-full flex items-center gap-3 hover:bg-grey-400/50"
          }
        >
          <Home width={22} />
          <span className="hidden md:block">{t("Home.Nav.Home")}</span>
        </NavLink>

        <div className="p-6 pb-2 hidden md:block text-primary text-sm uppercase">
          {t("Home.NavTitle.Settings")}
        </div>

        <NavLink
          to={keycloak.createAccountUrl()}
          className={({ isActive }) =>
            isActive
              ? "md:p-6 p-4 gradient-btn-opacity w-full flex items-center gap-3"
              : "md:p-6 p-4 w-full flex items-center gap-3 hover:bg-grey-400/50"
          }
        >
          <UserRound width={22} />
          <span className="hidden md:block">{t("Home.Nav.Account")}</span>
        </NavLink>

        {stripeEnabled && (
          <>
            <NavLink
              to="/subscription"
              className={({ isActive }) =>
                isActive
                  ? "md:p-6 p-4 gradient-btn-opacity w-full flex items-center gap-3"
                  : "md:p-6 p-4 w-full flex items-center gap-3 hover:bg-grey-400/50"
              }
            >
              {isSuscriber ? (
                <>
                  <ReceiptText width={22} />
                  <span className="hidden md:block">
                    {t("Home.Nav.Billing")}
                  </span>
                </>
              ) : (
                <>
                  <Sparkles width={22} />
                  <span className="hidden md:block">
                    {t("Home.Nav.Subscription")}
                  </span>
                </>
              )}
            </NavLink>
          </>
        )}
        {teamManagementEnabled && isTeamManager && (
          <>
            <div className="p-6 pb-2 hidden md:block text-primary text-sm uppercase">
              {t("Home.NavTitle.Team")}
            </div>
            <NavLink
              to="/team"
              className={({ isActive }) =>
                isActive
                  ? "md:p-6 p-4 gradient-btn-opacity w-full flex items-center gap-3"
                  : "md:p-6 p-4 w-full flex items-center gap-3 hover:bg-grey-400/50"
              }
            >
              <Users width={22} />
              <span className="hidden md:block">
                {t("Home.Nav.TeamManagement")}
              </span>
            </NavLink>
          </>
        )}
      </nav>

      <div className="text-base mb-3">
        <button
          className="flex gap-3 items-center md:px-6 px-5 py-3 hover:bg-grey-400/50 w-full"
          onClick={changeLang}
        >
          <Languages width={20} />
          <span className="hidden md:block">{t("Home.Nav.Language")} :</span>

          <div className="font-bold uppercase gap-3 hidden md:flex">
            {languages.map((lang) => {
              const classes =
                lang === i18next.language ? "text-primary" : "text-white";
              return (
                <div className={classes} key={lang}>
                  {lang}
                </div>
              );
            })}
          </div>
        </button>

        <button
          className="flex gap-3 items-center md:px-6 px-5 py-3 hover:bg-grey-400/50 w-full"
          onClick={() => keycloak.logout()}
        >
          <LogOut width={20} />
          <span className="hidden md:block">{t("Home.Nav.SignOut")}</span>
        </button>
      </div>
    </aside>
  );
};

export default Nav;
