
import GenerationsSection from "../../components/Generations";

type MyCreationsPageProps = {
  isFnacUserOrDemo?: boolean
}

export const MyCreationsPage = ({ isFnacUserOrDemo }: MyCreationsPageProps) => {


  return (
    <div className="flex flex-col bg-fnac-black overflow-auto w-full h-full md:p-8">
      <div className="h-full bg-fnac-black p-8 text-white md:p-8 xl:max-w-6xl xl:min-w-[1024px]">
      <GenerationsSection isFnacUserOrDemo={isFnacUserOrDemo} />
      </div>
    </div>
  );
};
