import { useEffect, useState } from 'react';
import { useStore } from '@nanostores/react';
import { $generations } from '../../../../store/generations';
import { ExpandableCard } from './ExpandableCard';
import { usePanelContext } from '../context/usePanelContext';

const AllGenerations = () => {
  const generations = useStore($generations);
  const { showAllGenerations, setIsAllGenerationsOpen, inProgressGeneration } =
    usePanelContext();

  // Définir initialement `activeCard` comme la première carte (la plus récente)
  const [activeCard, setActiveCard] = useState<number | null>(
    inProgressGeneration
      ? -1
      : generations.length > 0
        ? 0 // Ouvrir la première carte initialement (carte la plus récente)
        : null
  );

  useEffect(() => {
    setIsAllGenerationsOpen(showAllGenerations);
  }, [showAllGenerations, setIsAllGenerationsOpen]);

  return (
    <div className='flex h-full w-full flex-col rounded-2xl'>
      <div className={showAllGenerations ? 'flex h-full flex-col' : 'hidden'}>
        {/* Conteneur scrollable pour les cartes */}
        <div className='flex flex-grow flex-col gap-2 overflow-y-auto'>
          {inProgressGeneration && (
            <ExpandableCard
              key={-1}
              id={-1} // Identifiant unique pour la carte en cours
              isLoading={true}
              initialExpand={true}
              generation={inProgressGeneration}
              activeCard={activeCard}
              setActiveCard={setActiveCard}
            />
          )}
          {generations.map((generation, index) => (
            <ExpandableCard
              key={index} // Utilise l'index comme clé unique
              id={index} // Utilise l'index comme identifiant
              initialExpand={index === 0} // Ouvre la première carte initialement
              generation={generation}
              activeCard={activeCard}
              setActiveCard={setActiveCard}
            />
          ))}
        </div>

        {/* Bouton de fermeture fixe en bas */}
        
      </div>
    </div>
  );
};

export default AllGenerations;
