import { Trophy } from 'lucide-react';

import { useTranslation } from 'react-i18next';
import { ExpandableCard } from './ExpandableCard';
import { GenerationDTO, SelectedGenerationDTO } from '../../../../types';

const Results = ({
  generations,
  importFnct,
  isSubmissionVisible,
  isSubmitEnabled,
  hasAlreadySubmitted,
  handleSelectCard,
  handleSubmit,
}: {
  generations: GenerationDTO[];
  importFnct: (img: string) => void;
  isSubmissionVisible: boolean;
  isSubmitEnabled: boolean;
  hasAlreadySubmitted: boolean;
  handleSelectCard: (imgWithGenId: SelectedGenerationDTO) => void;
  handleSubmit: (e: React.MouseEvent) => void;
}) => {
  const { t } = useTranslation(['fnac']);

  return (
    <div className='font-si relative grid h-full w-full grid-rows-[min-content_1fr_min-content] gap-6 rounded-[32px] bg-fnac-dark-gray p-5 py-8 font-ccsign text-[14px]'>
      {/* Header */}
      <div className='mb-4 flex items-center justify-center gap-2'>
        <p className='text-ceter jnter max-w-[200px] text-base'>
          Je sélectionne une image et j'améliore si nécéssaire
        </p>
        <img className='h-[20px]' src={'v2/arrow.svg'} alt='' />
      </div>
      {/* Generations */}
      <div className='flex h-full w-full flex-col gap-4 overflow-auto'>
        {generations.map(({ genId, date, images }, index) => (
          <ExpandableCard
            key={genId}
            genId={genId}
            date={date}
            images={images}
            importFnct={importFnct}
            initialExpand={index === 0}
            index={generations.length - index}
            handleSelectCard={handleSelectCard}
          />
        ))}
      </div>
      {/* Submit */}
      {isSubmissionVisible && (
        <button
          className={`flex items-center justify-center space-x-2 rounded-2xl bg-fnac-mustard p-4 font-jakarta text-base font-extrabold text-fnac-black ${
            isSubmitEnabled && !hasAlreadySubmitted ? '' : 'opacity-50'
          }`}
          onClick={handleSubmit}
          disabled={!isSubmitEnabled || hasAlreadySubmitted}>
          <span className=''>
            {hasAlreadySubmitted
              ? t('fnac:Panel.AlreadySubmitted')
              : t('fnac:Panel.Submit')}
          </span>
          <Trophy width={14} height={14} />
        </button>
      )}
    </div>
  );
};

export default Results;
