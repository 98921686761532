import { useStore } from '@nanostores/react';
import { $generationsLeft } from '../store/generations-left';

//FIXME: this is the wrong way of doing thing because we loose the granularity of the store by adding stores together
const useAppContext = () => {
  const generationsLeft = useStore($generationsLeft);

  return { generationsLeft };
};
export default useAppContext;
