import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NavLink } from 'react-router-dom';

const FnacEndedModal = () => {
  return (
    <div className='fixed inset-0 z-100 flex items-center justify-center border border-hidden bg-fnac-gray bg-opacity-80 p-2 backdrop-blur-sm sm:p-4'>
      <div className='beink-radial-gradient flex w-full max-w-3xl flex-col items-center gap-5 rounded-3xl px-6 py-8 text-center sm:px-24 sm:py-16'>
        <h1 className='font-jakarta text-2xl font-extrabold uppercase text-white sm:text-3xl'>
          Le jeu concours 70 ans fnac est terminé
        </h1>
        <div className='flex flex-col items-center text-sm font-thin sm:text-lg'>
          <p>Vous souhaitez continuer à utiliser Beink Dream ?</p>
          <p>Prenez contact avec nos équipes</p>
        </div>
        <div className='flex gap-8 pt-5 text-sm font-bold sm:text-base'>
          <NavLink
            to='/gallery'
            className='flex items-center gap-2 rounded-xl border px-8 py-4'>
            <FontAwesomeIcon icon={faHome} />
            <div className=''>Tableau de bord</div>
          </NavLink>
          <a
            href='https://www.beink.fr/contact/'
            target='blank'
            className='rounded-xl bg-white px-8 py-4 text-fnac-black'>
            Nous contacter
          </a>
        </div>
      </div>
    </div>
  );
};

export default FnacEndedModal;
