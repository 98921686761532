import { Dispatch, SetStateAction, useState, createContext } from 'react';
import { DesignStyle } from '../../../../types';

type PanelInputContextType = {
  imagineInputText: string;
  setImagineInputText: Dispatch<SetStateAction<string>>;
  inpaintInputText: string;
  setInpaintInputText: Dispatch<SetStateAction<string>>;
  graphicStyle: DesignStyle | null;
  setGraphicStyle: Dispatch<SetStateAction<DesignStyle | null>>;
};

export const PanelInputContext = createContext<PanelInputContextType | undefined>(
  undefined
);

export const PanelInputProvider = ({ children }: { children: React.ReactNode }) => {
  const [imagineInputText, setImagineInputText] = useState<string>('');
  const [inpaintInputText, setInpaintInputText] = useState<string>('');
  const [graphicStyle, setGraphicStyle] = useState<DesignStyle | null>(null);

  return (
    <PanelInputContext.Provider
      value={{
        imagineInputText,
        setImagineInputText,
        inpaintInputText,
        setInpaintInputText,
        graphicStyle,
        setGraphicStyle,
      }}>
      {children}
    </PanelInputContext.Provider>
  );
};
