import { Loader2 } from 'lucide-react';
import { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Loading = memo(() => {
  const { t } = useTranslation(['panel']);
  const [showWait, setShowWait] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowWait(true);
    }, 1000);

    return () => {
      clearTimeout(timer);
      setShowWait(false);
    };
  }, []);

  return (
    <div className='font-si flex h-full flex-col items-center rounded-[18px] bg-fnac-dark-gray p-5 text-[14px]'>
      <Loader2 className='mx-auto animate-spin text-primary' size={30} />
      <p className='font-mono mt-3 text-grey-200'>{t('panel:Loading.title')}</p>
      {showWait && <p className='mt-5 italic'>{t('panel:Loading.waiting')}</p>}
    </div>
  );
});

export default Loading;
