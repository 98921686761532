import { Outlet } from "react-router-dom";
import { NavBar } from "./navbar/NavBar";
import { Header } from "./header/Header";

type BeinkLayoutProps = {
 fnacStopped: boolean
}

export const BeinkLayout = ({ fnacStopped }: BeinkLayoutProps) => (
  <main className=" relative grid grid-rows-[min-content_1fr] w-screen h-dvh overflow-hidden  ">

    <Header fnacStopped={fnacStopped} />
    <div className="relative grid grid-cols-[min-content_1fr] w-full h-full overflow-hidden">

      <NavBar />
      <Outlet />
    </div>
  </main>
);
