import type { Shortcuts } from '../types';
import {
  Keyboard,
  Pen,
  Eraser,
  Type,
  BoxSelect,
  Hand,
  Undo2,
  Redo2,
  ZoomOut,
  Pipette,
  Palette,
  CircleDot,
  Crop,
  Trash,
  BringToFront,
  SendToBack,
} from 'lucide-react';
import { useTranslation } from 'react-i18next';

export default function Shortcuts() {
  const { t } = useTranslation(['translation']);
  const shortcuts_list: Shortcuts[] = [
    {
      keys: ['B', 'D'],
      name: t('Whiteboard.Tools.pen'),
      icon: Pen,
    },
    {
      keys: ['E'],
      name: t('Whiteboard.Tools.eraser'),
      icon: Eraser,
    },
    {
      keys: ['V', 'S'],
      name: t('Whiteboard.Tools.select'),
      icon: BoxSelect,
    },
    {
      keys: ['R'],
      name: t('Whiteboard.Tools.crop'),
      icon: Crop,
    },
    {
      keys: ['H', 'M'],
      name: t('Whiteboard.Tools.hand'),
      icon: Hand,
    },
    {
      keys: ['T'],
      name: t('Whiteboard.Tools.text'),
      icon: Type,
    },
    {
      keys: ['P', 'I'],
      name: t('Whiteboard.Tools.eyedropper'),
      icon: Pipette,
    },
    {
      keys: ['C', 'N'],
      name: t('Whiteboard.Tools.colorPicker'),
      icon: Palette,
    },
    {
      keys: ['1', '2', '3', '4', '5'],
      name: t('Whiteboard.Tools.penSize'),
      icon: CircleDot,
    },
    {
      keys: ['pageUp'],
      name: t('Whiteboard.Tools.bringToFront'),
      icon: BringToFront,
    },
    {
      keys: ['pageDown'],
      name: t('Whiteboard.Tools.sendToBack'),
      icon: SendToBack,
    },
    {
      keys: ['delete'],
      name: t('Whiteboard.Tools.deleteSelected'),
      icon: Trash,
    },
    {
      keys: ['numpad 0'],
      name: t('Whiteboard.Tools.resetZoom'),
      icon: ZoomOut,
    },
    {
      keys: ['ctrl+Z'],
      name: t('Whiteboard.Tools.undo'),
      icon: Undo2,
    },
    {
      keys: ['ctrl+shift+Z'],
      name: t('Whiteboard.Tools.redo'),
      icon: Redo2,
    },
  ];

  return (
    <div className='group cursor-pointer'>
      <div className='max-sm:hidden grid aspect-square h-full place-content-center overflow-hidden rounded-md p-2 hover:bg-primary hover:text-grey'>
        <Keyboard />
      </div>

      <div className='invisible absolute bottom-full right-0 z-50 mb-5 opacity-0 transition-opacity duration-300 group-hover:visible group-hover:opacity-100'>
        <div className='border-1 relative z-40 min-w-[24rem] rounded-lg border-primary bg-grey shadow-lg'>
          <div className='flex justify-between border-b border-solid border-white/50 p-3'>
            <h4 className='font-semibold'>{t('Whiteboard.ShortcutTitle')}</h4>
          </div>

          <div className='px-2 py-3 text-base'>
            {shortcuts_list.map((item) => (
              <div
                key={item.name}
                className='mb-1 flex items-center gap-3 rounded-md bg-grey-200/5 px-2 py-1'>
                <item.icon size={16} className='mr-2' />
                <span className=''>{item.name}</span>
                <span className='font-mono ml-auto text-sm font-semibold uppercase text-grey'>
                  {item.keys.map((k) => (
                    <span
                      key={k}
                      className='ml-1 rounded-sm bg-white/70 px-2 py-1'>
                      {k}
                    </span>
                  ))}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
