import { BinaryFiles } from "@excalidraw/excalidraw/types/types";

let db: IDBDatabase;
let version = 1;
const dbName = 'files-db';
const storeName = 'files-store';

// Note for dev: DB can be reset with indexedDB.deleteDatabase(dbName)

export const initFilesDB = (): Promise<boolean> => {
  return new Promise((resolve) => {
    // open the connection
    const request = indexedDB.open(dbName);

    request.onupgradeneeded = () => {
      db = request.result;

      // if the data object store doesn't exist, create it
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName);
      }

      // no need to resolve here
    };

    request.onsuccess = () => {
      db = request.result;
      version = db.version;
      resolve(true);
    };

    request.onerror = (err) => {
      console.warn(`failed to init files DB: ${err}`)
      resolve(false);
    };
  });
};

export const getFiles = (): Promise<BinaryFiles> => {
  return new Promise((resolve) => {
    const request = indexedDB.open(dbName);

    request.onsuccess = () => {
      db = request.result;
      const tx = db.transaction(storeName, 'readonly');
      const store = tx.objectStore(storeName);
      const res = store.getAll();
      res.onsuccess = () => {
        resolve(res.result[0]);
      };
    };
  });
};

export const storeFiles = <T>(data: T): Promise<T|string|null> => {
  return new Promise((resolve) => {
    const request = indexedDB.open(dbName, version);

    request.onsuccess = () => {
      db = request.result;
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      store.put(data, storeName); // XXX: could be a key per user in order to handle multi-users
      resolve(data);
    };

    request.onerror = () => {
      const error = request.error?.message
      if (error) {
        resolve(`failed to store files: ${error}`);
      } else {
        resolve('failed to store files: unknown error');
      }
    };
  });
};