import { faEye } from '@fortawesome/free-solid-svg-icons';
import LastGeneration from './LastGeneration';
import AllGenerations from './AllGenerations'; // Ajout de l'import pour AllGenerations
import { usePanelContext } from '../context/usePanelContext';
import { useTranslation } from 'react-i18next';
import SimpleButton from '../../../lib/SimpleButton';

export type GenerationFromAPI = {
  createdAt: string;
  urls: string[];
  prompt: string;
  graphicStyle: string;
};

type ResultsProps = {
  variant: 'full' | 'reduced';
};

export const Results = ({ variant }: ResultsProps) => {
  const { setShowAllGenerations, showAllGenerations } = usePanelContext();
  const { t } = useTranslation(['panel']);
  const { isPanelOpen } = usePanelContext();

  return (
    <div
      className={`flex flex-col gap-2 pb-2 ${
        variant === 'reduced'
          ? 'items-center rounded-3xl'
          : 'items-center rounded-xl bg-d-surface-bg-tertiary p-2'
      } ${isPanelOpen ? 'md:flex' : 'hidden md:flex'} `}>
      {/* Conteneur LastGeneration avec style conditionnel selon le mode */}
      <div
        className={`flex w-full flex-col items-center justify-center gap-4 ${
          variant === 'full' ? 'w-full' : 'w-full max-w-[60px]'
        } ${variant === 'reduced' ? 'p-0' : 'p-0'}`}>
        {/* Affichage de LastGeneration ou de toutes les générations selon showAllGenerations */}
        {showAllGenerations ? (
          <AllGenerations /> // Utilisation de AllGenerations pour afficher toutes les générations
        ) : (
          <LastGeneration isPanelOpen={variant === 'full'} />
        )}
      </div>

      {/* Bouton "Voir toutes les générations", visible uniquement en mode Full et si showAllGenerations est false */}
      {variant === 'full' && !showAllGenerations && (
        <SimpleButton
          variant='tertiary'
          onClick={() => setShowAllGenerations(true)}
          leftIcon={faEye}
          format='rounded'
          size='xs'
          round>
          {t('panel:Results.seeAllGenerations')}
        </SimpleButton>
      )}
    </div>
  );
};
