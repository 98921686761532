import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExternalLink } from "lucide-react";

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

interface CreatorCTAProps {
  fnacStopped: boolean;
}

export const CreatorCTA: React.FC<CreatorCTAProps> = ({ fnacStopped }) => {
  const { t } = useTranslation(["translation"]);
  return (
    <NavLink
      to="/creator"
      className={`flex space-x-2 justify-center items-center bg-fnac-mustard px-6 py-4 text-sm font-jakarta font-bold border border-hidden rounded-md hover:scale-[1.02]
  ${fnacStopped ? "text-white beink-linear-gradient" : "text-fnac-dark-gray"}`}
    >
      {
        fnacStopped ?
          <>
            <span>{t("Home.LaunchApp")}</span>
            <ExternalLink width={20} />
          </> :
          <>
            <span className="text-fnac-black">{t("Header.CreatorCTA")}</span>
            <FontAwesomeIcon icon={faTrophy} width={12} height={12} color="#13100D" />
          </>
      }

    </NavLink>
  );
};
