import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Member, UserRole } from '../types';
import { teamManagementEnabled } from '../helpers/FeatureFlags';
import { Navigate } from 'react-router-dom';

const TeamManagementPage = () => {
  const { t } = useTranslation(['translation']);

  const { keycloak } = useKeycloak();
  const userRoles: string[] = keycloak.realmAccess?.roles || [];
  const isTeamManager = userRoles.includes(UserRole.TeamManager);

  const [members, setMembers] = useState<Member[]>([]);

  useEffect(() => {
    if (!keycloak.token) return;

    fetch(`${import.meta.env.VITE_API_URL}/my-team-members`, {
      headers: { authorization: `Bearer ${keycloak.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        setMembers(data as Member[]);
      })
      .catch(console.error);
  }, [keycloak]);

  if (!teamManagementEnabled || !isTeamManager) {
    return <Navigate to='/' replace={true} />;
  }

  return (
    <div className='ml-14 mt-20 p-6 md:ml-64 md:p-12'>
      <div className='p-6'>
        <div className='mb-12 text-balance text-center'>
          <h2 className='mb-4 text-4xl font-extrabold leading-tight text-primary'>
            {t('Team.Title')}
          </h2>
          <p className='mb-4 font-light'>{t('Team.Description')}</p>
        </div>
        <div className='flex'>
          <div className='mb-4 flex-grow text-2xl font-extrabold md:text-3xl'>
            {t('Team.Stats.Title')}
          </div>
        </div>
        <div className='space-between mb-4 flex flex-col rounded-lg border border-grey-200 bg-grey shadow-md'>
          <div className='flex flex-col rounded-md p-4 text-lg lg:flex-row lg:justify-between'>
            <div className='flex flex-col p-4 md:flex-row'>
              <div className='grow font-bold md:mr-2'>
                {t('Team.Stats.Count')}
              </div>
              <div className='text-center font-bold md:text-right'>
                {members.length}
              </div>
            </div>
          </div>
        </div>
        <div className='mb-4 text-2xl font-extrabold md:text-3xl'>
          {t('Team.Title')}
        </div>
        <div className='flex rounded-lg border border-grey-200 bg-grey shadow-md'>
          <MemberList members={members} />
        </div>
      </div>
    </div>
  );
};

export default TeamManagementPage;

const MemberList = ({ members }: { members: Member[] }) => {
  const { t } = useTranslation();

  return (
    <div className='flex w-full flex-col'>
      <div className='hidden justify-between rounded-md p-4 text-xl font-bold underline underline-offset-4 lg:flex'>
        <div className='grow p-4'>{t('Team.Metadata.User')}</div>
        <div className='flex flex-row'>
          <div className='p-4'>{t('Team.Metadata.Role')}</div>
        </div>
      </div>
      {members.map((member: Member) => (
        <div
          key={member.id}
          className='flex flex-col justify-between rounded-md p-4 lg:flex-row'>
          <div className='px-4'>
            <div className='text-xl font-bold'>{`${member.firstName} ${member.lastName}`}</div>
            <div className='text-medium truncate italic'>{member.email}</div>
          </div>
          <div className='flex flex-row justify-between'>
            <div className='px-4 py-2'>
              {member.role.some((role: string) => role === UserRole.TeamManager)
                ? t('Team.Role.Manager')
                : t('Team.Role.User')}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
