import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ModeratorHeaderCardProps = {
  shouldResfreshStats: number;
};

export const ModeratorHeaderCard: React.FC<ModeratorHeaderCardProps> = ({
  shouldResfreshStats,
}: ModeratorHeaderCardProps) => {
  const { t } = useTranslation(['fnac']);
  const { keycloak } = useKeycloak();

  const [totalSubmissions, setTotalSubmissions] = useState<number>(0);
  const [visibleSubmissions, setVisibleSubmissions] = useState<number>(0);
  const [reportedSubmissions, setReportedSubmissions] = useState<number>(0);
  const [blockedSubmissions, setBlockedSubmissions] = useState<number>(0);

  useEffect(() => {
    if (!keycloak.token) return;

    fetch(`${import.meta.env.VITE_API_URL}/event/fnac70/moderator-info`, {
      headers: { authorization: `Bearer ${keycloak.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) return;

        setTotalSubmissions(data.totalSubmissions);
        setVisibleSubmissions(data.visibleSubmissions);
        setReportedSubmissions(data.reportedSubmissions);
        setBlockedSubmissions(data.blockedSubmissions);
      })
      .catch((err) => {
        console.error('Oups, An error has occurred', err);
      });
  }, [keycloak, shouldResfreshStats]);

  return (
    <div className='flex flex-col gap-4 p-6 md:p-8'>
      <div className='flex flex-col pr-10 text-xl uppercase'>
        <h1 className='font-jakarta text-4xl font-black text-white'>
          {t('fnac:MyContest.Moderator.Title')}
        </h1>
      </div>
      <div className='flex flex-col text-left text-base'>
        <p>
          <span className='font-jakarta font-bold text-white'>
            {t('fnac:MyContest.Moderator.TotalSubmissions')}
          </span>{' '}
          <span className='font-jakarta font-bold text-fnac-mustard'>
            {totalSubmissions}
          </span>
        </p>
        <p>
          <span className='font-jakarta font-bold text-white'>
            {t('fnac:MyContest.Moderator.VisibleSubmissions')}
          </span>{' '}
          <span className='font-jakarta font-bold text-fnac-mustard'>
            {visibleSubmissions}
          </span>
        </p>
        <p>
          <span className='font-jakarta font-bold text-white'>
            {t('fnac:MyContest.Moderator.ReportedSubmissions')}
          </span>{' '}
          <span className='font-jakarta font-bold text-fnac-mustard'>
            {reportedSubmissions}
          </span>
        </p>
        <p>
          <span className='font-jakarta font-bold text-white'>
            {t('fnac:MyContest.Moderator.BlockedSubmissions')}
          </span>{' '}
          <span className='font-jakarta font-bold text-fnac-mustard'>
            {blockedSubmissions}
          </span>
        </p>
      </div>
    </div>
  );
};
