import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import { $generationsLeft } from '../store/generations-left';
import { DesignStyle, type DataAiFromApi } from '../types';
import useAppContext from './useAppContext';
import { dataURItoBlob } from '../helpers/ImageManipulation';
import { $generations } from '../store/generations';
import { usePanelContext } from '../components/Creator/Panel/context/usePanelContext';

const useInpaint = () => {
  const { t } = useTranslation(['panel']);
  const { generationsLeft } = useAppContext();
  const { keycloak } = useKeycloak();

  const { setInProgressGeneration, setError } = usePanelContext();

  const inpaintImage = async ({
    prompt,
    scribble,
    mask,
  }: {
    prompt: string;
    scribble: string;
    mask: string;
  }) => {
    if (!scribble.startsWith('data:image/png;base64,')) {
      throw new Error('Invalid scribble format');
    }

    if (!mask.startsWith('data:image/png;base64,')) {
      throw new Error('Invalid mask format');
    }

    if (!keycloak.token) {
      return setError(t('panel:Error.wrongKey'));
    }

    if (generationsLeft < 1) {
      return setError(t('panel:Error.noAttemptLeft'));
    }

    setError('');

    setInProgressGeneration({
      createdAt: new Date().toISOString(),
      urls: ["in-progress-0", "in-progress-1", "in-progress-2", "in-progress-3"],
      prompt: prompt,
      graphicStyle: DesignStyle.HandSketch,
    });

    const formData = new FormData();
    formData.append('prompt', prompt);
    formData.append('source', 'Webapp');
    formData.append('scribble', dataURItoBlob(scribble), 'scribble.png');
    formData.append('mask', dataURItoBlob(mask), 'mask.png');

    const url = new URL('/ai/inpaint-sync', import.meta.env.VITE_API_URL);

    let success = false;

    try {
      const res = await fetch(url, {
        body: formData,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          authorization: `Bearer ${keycloak.token}`,
        },
      });

      const data = (await res.json()) as DataAiFromApi;

      if (data.status === 'completed') {
        const {
          urls,
          generationsLeft,
          createdAt,
          prompt,
          graphicStyle,
        } = data;
        $generationsLeft.set(generationsLeft);
        $generations.set([
          {
            createdAt: createdAt,
            urls: urls ?? [],
            prompt: prompt,
            graphicStyle: graphicStyle,
          },
          ...$generations.get(),
        ]);

        success = true;
        return success;
      }

      setError(t('panel:Error.default'));
    } catch (err) {
      console.error(err);
      setError(t('panel:Error.default'));
    } finally {
      setInProgressGeneration(null);
    }

    return success;
  };

  return {
    inpaintImage,
  };
};

export default useInpaint;
