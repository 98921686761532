import React from 'react';
import { LittleStampShape } from '../auth/LittleStampShape';
import NonAdherentModal from './NonAdherentModal';

interface ModalProps {
  isAllowedToParticipate: boolean;
  isOpen: boolean;
  onClose: () => void;
}

export const WelcomeModal: React.FC<ModalProps> = ({
  isAllowedToParticipate,
  isOpen,
  onClose,
}) => {
  if (!isOpen) return null;

  return isAllowedToParticipate ? (
    <div
      className={`fixed inset-0 z-100 flex items-center justify-center border border-hidden bg-fnac-gray bg-opacity-80 p-4 backdrop-blur-sm ${
        isOpen ? 'no-doc-scroll' : ''
      }`}>
      <div className="w-full max-w-3xl overflow-hidden rounded-3xl bg-fnac-light-gray bg-[url('/v2/fnac-70-pattern.png')]">
        <div className='h-full w-full bg-fnac-light-gray bg-opacity-80 px-8 py-10 md:px-16'>
          <div className='flex flex-col items-center gap-2 text-center'>
            <LittleStampShape label='Concours' />
            <h2 className='flex flex-col text-2xl font-extrabold uppercase'>
              <span className='font-jakarta font-extrabold text-fnac-black'>
                Et si vous deveniez l'artiste de la
              </span>
              <span className='font-ccsign font-bold text-fnac-mustard'>
                carte Fnac+ édition spéciale 70 ans ?
              </span>
            </h2>
            <p className='text-sm text-fnac-black sm:text-base'>
              Vous pourrez gagner un des nombreux lots Samsung et votre visuel
              imprimé sur la prochaine carte Fnac+ édition spéciale 70 ans !
            </p>

            <img src='/v2/kv-welcome.png' className='w-2/3 pb-4' />

            {!isAllowedToParticipate && (
              <p className='text-base text-fnac-black'>
                Le concours est réservé aux adhérent Fnac Plus et Fnac One, mais
                vous pouvez voter pour votre visuel préféré.
              </p>
            )}

            <button
              onClick={onClose}
              className='rounded-xl border border-hidden bg-fnac-mustard px-8 py-4 font-jakarta font-bold text-fnac-black'>
              Comment ça marche ?
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <NonAdherentModal></NonAdherentModal>
  );
};
