import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WandSparkles } from "lucide-react";
import React, { useEffect, useRef } from "react";

interface ModalProps {
  isOpen: boolean;
  step: number;
  designImage?: string;
  onNext: () => void;
}

const STEP_TITLE: Record<number, string> = {
  1: "Je dessine sur le tableau",
  2: "Je décris mon dessin",
  3: "Je soumets mon visuel",
};

const STEP_SUBTITLE: Record<number, string> = {
  1: "Ce que m'évoque la Fnac",
  2: "Puis je le sélectionne sur le tableau pour que Beink Dream génère mon idée",
  3: "Pour participer au concours",
};

const STEP_CTA: Record<number, string> = {
  1: "Suivant ",
  2: "Suivant ",
  3: "C'est parti",
};

const STEP_ICON: Record<number, JSX.Element> = {
  1: <FontAwesomeIcon icon={faArrowRight} />,
  2: <FontAwesomeIcon icon={faArrowRight} />,
  3: <WandSparkles />,
};

const STEP_VIDEO: { [key: number]: string } = {
  1: "v2/tuto-step-1.mp4",
  2: "v2/tuto-step-2.mp4",
  3: "v2/tuto-step-3.mp4",
};

export const TutorialModal: React.FC<ModalProps> = ({
  isOpen,
  step,
  onNext,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [step]);


  if (!isOpen) return null;

  return (
    <div
      className={`z-100 border border-hidden fixed inset-0 bg-fnac-gray bg-opacity-80 backdrop-blur-sm flex items-center justify-center p-4 ${isOpen ? "no-doc-scroll" : ""
        }`}

    >
      <div className="beink-radial-gradient w-full max-w-3xl rounded-3xl p-6 sm:p-12">
        <div className="flex flex-col items-center text-center">
          <div className="border-hidden rounded-2xl border bg-white text-fnac-black">
            <video
              ref={videoRef}
              className="w-full rounded-2xl md:max-w-lg"
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={STEP_VIDEO[step]} type="video/mp4" />
              Le navigateur ne supporte pas la lecture de vidéos.
            </video>
          </div>
          <h2 className="mb-3 mt-6 text-xl uppercase">
            <span className="mr-2 font-ccsign font-bold text-fnac-mustard">
              {step}.
            </span>
            <span className="font-jakarta font-extrabold text-white">
              {STEP_TITLE[step]}
            </span>
          </h2>
          <p className="mb-6 text-white">{STEP_SUBTITLE[step]}</p>

          <button
            onClick={onNext}
            className="flex items-center justify-center space-x-2 rounded-xl border border-fnac-mustard bg-fnac-mustard px-8 py-4 font-jakarta font-bold text-fnac-black transition-transform duration-75 hover:scale-[102%]"
          >
            <div>{STEP_CTA[step]}</div>
            <div className="">
              {STEP_ICON[step]}
            </div>
          </button>
          {/* Step indicator */}
          <div className="flex gap-2 mt-6">
            <div className={`w-2 h-2 rounded-full bg-white ${step === 1 ? "bg-opacity-100" : "bg-opacity-50"
              }`} />
            <div className={`w-2 h-2 rounded-full bg-white ${step === 2 ? "bg-opacity-100" : "bg-opacity-50"
              }`} />
            <div className={`w-2 h-2 rounded-full bg-white ${step === 3 ? "bg-opacity-100" : "bg-opacity-50"
              }`} />
            <div />
          </div>

        </div>
      </div>
    </div>
  );
};
