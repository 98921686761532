/* eslint-disable @typescript-eslint/ban-ts-comment */
import Colorful from '@uiw/react-color-colorful';
import { useGesture } from '@use-gesture/react';
import { fabric } from 'fabric';
import { IEvent, Pattern } from 'fabric/fabric-impl';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import {
  BringToFront,
  Crop,
  Download,
  Eraser,
  Hand,
  Image,
  MousePointer2,
  Pen,
  Pipette,
  Redo,
  SendToBack,
  Trash,
  Type,
  Undo2,
} from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isTouchDevice, isTouchpad, isMobile } from '../helpers/device';
import { useEditorActions } from '../hooks/useEditorActions';
import { PenSize, EraserSize, type Tools, type ToolsList } from '../types';

import Shortcuts from '../components/Shortcuts';
import '../styles/toolbar.scss';
import { WelcomeModal } from '../v2/components/modal/WelcomeModal';
import { TutorialModal } from '../v2/components/modal/TutorialModal';
import { BeinkPanelNext } from '../v2/components/panel/BeinkPanelNext';
import { SubmissionModal } from '../v2/components/modal/SubmissionModal/SubmissionModal';
import { ThankModal } from '../v2/components/modal/ThankModal';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { dataURItoBlob } from '../helpers/ImageManipulation';
import FnacEndedModal from '../v2/components/modal/FnacEndedModal';
import { fnacStopped } from '../helpers/FeatureFlags';

type WhiteboardPageProps = {
  isFnacUser?: boolean;
  isFnacDemo?: boolean;
  isFnacModerator?: boolean;
};

export default function WhiteboardPage({
  isFnacUser = false,
  isFnacDemo = false,
  isFnacModerator = false,
}: WhiteboardPageProps) {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();

  const { editor, onReady, selectedObjects } = useFabricJSEditor();

  const fabricCanvas = useRef<HTMLInputElement>(null);
  const uploadInput = useRef<HTMLInputElement>(null);
  const colorInput = useRef<HTMLInputElement>(null);

  const [tool, setTool] = useState<Tools>('pen');
  const [color, setColor] = useState<string>('#181515');
  const [penSize, setPenSize] = useState<PenSize>(PenSize.md);
  const [eraserSize, setEraserSize] = useState<EraserSize>(EraserSize.md);
  const [zoom, setZoom] = useState<number>(0.5);
  const [hasObjectSelected, setHasObjectSelected] = useState<boolean>(false);
  const [hasImageSelected, setHasImageSelected] = useState<boolean>(false);

  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [isTouchPan, setIsTouchPan] = useState<boolean>(false);
  const [openColorpicker, setOpenColorpicker] = useState<boolean>(false);
  const [historyUndo, setHistoryUndo] = useState<string[]>([]);
  const [historyRedo, setHistoryRedo] = useState<string[]>([]);
  const [clipboard, setClipboard] = useState<fabric.Object[] | null>(null);

  const toolsList: ToolsList[] = [
    {
      name: 'pen',
      title: t('Whiteboard.Tools.pen'),
      icon: Pen,
    },
    {
      name: 'select',
      title: t('Whiteboard.Tools.select'),
      icon: MousePointer2,
    },
    {
      name: 'crop',
      title: t('Whiteboard.Tools.crop'),
      icon: Crop,
    },
    {
      name: 'hand',
      title: t('Whiteboard.Tools.hand'),
      icon: Hand,
    },
    {
      name: 'text',
      title: t('Whiteboard.Tools.text'),
      icon: Type,
    },
    {
      name: 'eyedropper',
      title: t('Whiteboard.Tools.eyedropper'),
      icon: Pipette,
    },
    {
      name: 'eraser',
      title: t('Whiteboard.Tools.eraser'),
      icon: Eraser,
    },
  ];

  const {
    cropImage,
    historySave,
    uploadImage,
    changeColor,
    removeSelectedObject,
    addText,
    resetZoom,
    undoAction,
    redoAction,
    changeTool,
    penTool,
    toggleLockObjects,
    eyedropperAction,
    changePenSize,
    changeEraserSize,
    zoomEditor,
    canvasZoom,
    panEditor,
    lockCanvas,
    importImage,
    // TODO @Aurel: Rework exportImage/exportImageAndMasksOverlay to handle both cases
    exportImage,

    downloadImage,
    dropImage,
    selectAllObjects,
    bringSelectionToFront,
    bringSelectionToBack,
    copyObject,
    pasteHandler,
    pasteHandlerWithoutImport, // Fnac only
  } = useEditorActions({
    editor,
    tool,
    setTool,
    setColor,
    setPenSize,
    setEraserSize,
    setZoom,
    selectedObjects,
    setIsDragOver,
    historyUndo,
    setHistoryUndo,
    historyRedo,
    setHistoryRedo,
    clipboard,
    setClipboard,
  });

  // Selected objects listener
  // TODO @Aurel: all of this should be reworked and optimized some day
  useEffect(() => {
    if (selectedObjects) {
      const hasSomeSelectedObjects = Boolean(selectedObjects.length);
      setHasObjectSelected(hasSomeSelectedObjects);

      if (hasSomeSelectedObjects) {
        const hasSomeSelectedImages =
          selectedObjects.some((o: fabric.Object) => o.type === 'image') ??
          false;

        setHasImageSelected(hasSomeSelectedImages);
      } else {
        setHasObjectSelected(false);
        setHasImageSelected(false);
      }
    }
  }, [selectedObjects]);

  // Canvas Events listener
  useEffect(() => {
    if (!editor || !fabric) return;

    let isDragging = false,
      lastPosX = 0,
      lastPosY = 0;

    const startPan = (evt: MouseEvent | TouchEvent) => {
      if (tool !== 'hand') {
        if (tool === 'pen') {
          //@ts-ignore
          editor.canvas._isCurrentlyDrawing = false;
          editor.canvas.isDrawingMode = false;
          editor.canvas.renderTop();
        }

        editor.canvas.defaultCursor = 'grab';
        editor.canvas.selection = false;
        editor.canvas.interactive = false;
        editor.canvas.discardActiveObject();
        toggleLockObjects(true);
      }

      isDragging = true;

      if (isTouchDevice()) {
        if ((evt as TouchEvent).touches?.length) {
          lastPosX = (evt as TouchEvent).touches[0].clientX;
          lastPosY = (evt as TouchEvent).touches[0].clientY;
        }
      } else {
        lastPosX = (evt as MouseEvent).clientX;
        lastPosY = (evt as MouseEvent).clientY;
      }
    };

    const mouseWheel = (opt: IEvent<Event>) => {
      if (!editor) return;
      const evt = opt.e as WheelEvent;

      // test if Touchpad or Mouse - ctrlKey is used for 2 fingers pinch
      if (isTouchpad(evt as WheelEvent) && !evt.ctrlKey) {
        panEditor(-evt.deltaX, -evt.deltaY);
      } else {
        zoomEditor(evt);
      }
      evt.preventDefault();
      evt.stopPropagation();
    };

    const mouseDown = (opt: IEvent<Event>) => {
      if (!editor) return;
      if (openColorpicker) setOpenColorpicker(false);

      const evt = opt.e as MouseEvent;

      if (evt.ctrlKey === true || evt.button == 2 || tool === 'hand')
        return startPan(evt);

      if (tool === 'text') return addText(opt);
    };

    const mouseMove = (opt: IEvent<Event>) => {
      if (isDragging && editor.canvas.viewportTransform) {
        const evt = opt.e as MouseEvent;
        const touchEvt = opt.e as TouchEvent;
        let panX: number, panY: number;

        if (isTouchDevice() && touchEvt.touches.length) {
          panX = touchEvt.touches[0].clientX;
          panY = touchEvt.touches[0].clientY;
        } else {
          panX = evt.clientX;
          panY = evt.clientY;
        }

        panEditor(panX - lastPosX, panY - lastPosY);
        lastPosX = panX;
        lastPosY = panY;
      }
    };

    const mouseUp = (opt: IEvent<Event>) => {
      if (!editor) return;

      if (tool === 'eyedropper') {
        changeTool('pen');
        const { x, y } = editor.canvas.getPointer(opt.e, true);
        eyedropperAction({ x, y });
      }

      if (tool === 'eraser') historySave();

      if (isDragging) {
        isDragging = false;
        if (tool !== 'hand') changeTool(tool);
      }

      editor.canvas.requestRenderAll();
    };

    // Editor EVENTS Listener
    editor.canvas.on('mouse:wheel', mouseWheel);
    editor.canvas.on('mouse:down', mouseDown);
    editor.canvas.on('mouse:move', mouseMove);
    editor.canvas.on('mouse:up', mouseUp);
    fabricCanvas.current?.addEventListener('keydown', shortcutsHandler);
    fabricCanvas.current?.addEventListener('dblclick', cropImage);
    editor.canvas.on('object:added', historySave);
    editor.canvas.on('object:removed', historySave);
    editor.canvas.on('object:modified', historySave);

    return () => {
      editor.canvas.off('mouse:wheel', mouseWheel);
      editor.canvas.off('mouse:down', mouseDown);
      editor.canvas.off('mouse:move', mouseMove);
      editor.canvas.off('mouse:up', mouseUp);
      fabricCanvas.current?.removeEventListener('keydown', shortcutsHandler);
      fabricCanvas.current?.removeEventListener('dblclick', cropImage);
      editor.canvas.off('object:added', historySave);
      editor.canvas.off('object:removed', historySave);
      editor.canvas.off('object:modified', historySave);
    };
  }, [editor]);

  // Touch device Events (pan & zoom)

  const bind = useGesture(
    {
      onPinch: ({ offset, origin }) => {
        canvasZoom(offset[0], origin[0], origin[1]);
      },
      onDrag: ({ touches, delta }) => {
        if (isTouchPan) panEditor(delta[0], delta[1]);
        else if (touches === 2) {
          setIsTouchPan(true);
          lockCanvas();
        }
      },
      onDragEnd: () => {
        if (isTouchPan) {
          changeTool(tool);
          setIsTouchPan(false);
        }
      },
    },
    {
      pinch: {
        threshold: 0.15,
      },
      eventOptions: {
        passive: false,
      },
    }
  );

  // INIT CANVAS

  const initCanvas = () => {
    if (!editor || !fabric) return;

    if (isMobile()) {
      // @ts-ignore
      fabric.devicePixelRatio = 1;
    }

    resetZoom();

    editor.canvas.preserveObjectStacking = true;
    editor.canvas.fireRightClick = true;
    editor.canvas.stopContextMenu = true;
    editor.canvas.imageSmoothingEnabled = false;
    editor.canvas.freeDrawingBrush.color = color;
    editor.canvas.freeDrawingBrush.width = penSize;
    editor.canvas.freeDrawingBrush.decimate = 3;
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = '#12f4ad';

    resizeCanvas();
    addBackground();
    penTool();

    editor.canvas.renderAll();
  };

  const addBackground = () => {
    if (!editor || !fabric) return;
    editor.canvas.setBackgroundColor(
      { source: './dot-grid-light.png', repeat: 'repeat' } as Pattern,
      () => editor.canvas.renderAll()
    );
  };

  const resizeCanvas = () => {
    if (!editor?.canvas) return;
    const w = window.innerWidth;
    const h = window.innerHeight;
    editor.canvas.setWidth(w);
    editor.canvas.setHeight(h);
  };

  useEffect(() => {
    initCanvas();
    window.addEventListener('resize', resizeCanvas);

    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, [editor?.canvas.backgroundImage]);

  // SHORTCUTS - call in Events listener useEffect
  const shortcutsHandler = (e: KeyboardEvent) => {
    if (!editor?.canvas) return;
    const target = editor?.canvas.getActiveObject() as fabric.IText;
    if (target?.type == 'textbox' && target?.isEditing) return;

    // Copy / Paste on canvas
    if (e.key === 'c' && e.ctrlKey) copyObject();
    if (e.key === 'v' && e.ctrlKey) {
      if (isFnacUser || isFnacDemo) {
        pasteHandlerWithoutImport();
      } else {
        pasteHandler();
      }
    }

    if (['A', 'a'].includes(e.key) && e.ctrlKey) {
      e.preventDefault();
      selectAllObjects();
    }
    if (['Delete', 'Backspace'].includes(e.key)) removeSelectedObject();
    if (e.key === '0') resetZoom();
    if (['d', 'b', 'D', 'B'].includes(e.key)) changeTool('pen');
    if (['h', 'H', 'm', 'M'].includes(e.key)) changeTool('hand');
    if (['s', 'v', 'S', 'V'].includes(e.key)) changeTool('select');
    if (['e', 'E'].includes(e.key)) changeTool('eraser');
    if (['r', 'R'].includes(e.key)) changeTool('crop');
    if (['t', 'T'].includes(e.key)) changeTool('text');
    if (['n', 'N', 'c', 'C'].includes(e.key) && colorInput.current)
      colorInput.current.click();
    if (['p', 'P', 'i', 'I'].includes(e.key)) changeTool('eyedropper');
    if (['z', 'Z'].includes(e.key) && e.ctrlKey && !e.shiftKey) undoAction();
    if (['Z', 'z'].includes(e.key) && e.ctrlKey && e.shiftKey) redoAction();
    if (['&', '1'].includes(e.key)) {
      changePenSize(PenSize.sm);
      changeEraserSize(EraserSize.sm);
    }
    if (['é', '2'].includes(e.key)) {
      changePenSize(PenSize.md);
      changeEraserSize(EraserSize.md);
    }
    if (['"', '3'].includes(e.key)) {
      changePenSize(PenSize.lg);
      changeEraserSize(EraserSize.lg);
    }
    if (["'", '4'].includes(e.key)) {
      changePenSize(PenSize.xlg);
      changeEraserSize(EraserSize.xlg);
    }
    if (['(', '5'].includes(e.key)) {
      changePenSize(PenSize.xxlg);
      changeEraserSize(EraserSize.xxlg);
    }
    if (e.key === 'PageUp') bringSelectionToFront();
    if (e.key == 'PageDown') bringSelectionToBack();
  };

  // Get Fnac adherent and alreadySubmitted statuses
  const [isFnacAdherent, setIsFnacAdherent] = useState<boolean>(false);
  const [hasAlreadySubmitted, setHasAlreadySubmitted] =
    useState<boolean>(false);
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState<boolean>(
    ((isFnacUser && !hasAlreadySubmitted) || isFnacDemo) && !fnacStopped
  );
  const [isSubmissionModalOpen, setIsSubmissionModalOpen] =
    useState<boolean>(false);
  const [isThankModalOpen, setIsThankModalOpen] = useState<boolean>(
    hasAlreadySubmitted && !fnacStopped
  );
  const [submissionImg, setSubmissionImg] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  if (isFnacUser) {
    useEffect(() => {
      // Fetch user info to get Fnac membership attributes
      keycloak.loadUserInfo().then((userInfo: any) => {
        const fnacPlus = userInfo.membership_is_fnacplus?.toLocaleLowerCase();
        const fnacPlusEndDateStr =
          userInfo.membership_enddate?.toLocaleLowerCase();
        const fnacOne = userInfo.membership_is_adherentone?.toLocaleLowerCase();

        if (fnacOne == 'true' || fnacOne == 'one') {
          setIsFnacAdherent(true);
        } else if (fnacPlus == 'true') {
          // Fnac Plus: need to check that membership end date is >= now
          const fnacPlusEndDate = parseFnacPlusEndDate(fnacPlusEndDateStr);
          if (!fnacPlusEndDate) {
            console.error(
              'failed to parse Fnac Plus end date with YYYYmmdd format: ',
              fnacPlusEndDateStr
            );
          } else {
            const now = new Date();
            const nowWithoutTime = new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate()
            );
            if (fnacPlusEndDate >= nowWithoutTime) {
              setIsFnacAdherent(true);
            }
          }
        }

        // Fetch challenger info to get the "already submitted" status
        fetch(`${import.meta.env.VITE_API_URL}/event/fnac70/challenger-info`, {
          headers: { authorization: `Bearer ${keycloak.token}` },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) return;

            setHasAlreadySubmitted(data.hasAlreadySubmitted);
            if (!data.hasAlreadySubmitted) return;

            setIsWelcomeModalOpen(false);

            // Fetch submissin to display it in the thank modal
            fetch(
              new URL('/event/fnac70/submission', import.meta.env.VITE_API_URL),
              {
                headers: { authorization: `Bearer ${keycloak.token}` },
              }
            )
              .then((res) => res.json())
              .then((data) => {
                if (data.error) return;

                setSubmissionImg(data.url);
                setIsThankModalOpen(true && !fnacStopped);
              });
          })
          .catch((err) => {
            console.error('Oups, An error has occurred', err);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }, [keycloak]);
  }

  const [tutorialModalStep, setTutorialModalStep] = useState<number>(0);
  const [genId, setGenId] = useState<string>('');
  const [img, setImg] = useState<string>('');

  const handleOnWelcomeModalClose = () => {
    setIsWelcomeModalOpen(false);
    if (isFnacAdherent || isFnacDemo || isFnacModerator) {
      setTutorialModalStep(1);
    } else {
      return navigate('/gallery');
    }
  };

  const handleOnTutorialModalNext = () => {
    setIsWelcomeModalOpen(false);
    setTutorialModalStep(tutorialModalStep > 2 ? 0 : tutorialModalStep + 1);
  };

  const handleOnSubmissionModalClose = () => {
    setIsSubmissionModalOpen(false);
  };

  const handleOnSubmissionStart = ({
    genId,
    img,
  }: {
    genId: string;
    img: string;
  }) => {
    setIsWelcomeModalOpen(false);
    setTutorialModalStep(0);
    setIsSubmissionModalOpen(true);
    setIsThankModalOpen(false);

    setGenId(genId);
    setImg(img);
  };

  const handleOnSubmissionDone = (mergedImg: string, img: string) => {
    const formData = new FormData();
    formData.append('genId', genId);
    formData.append('submission', dataURItoBlob(mergedImg), 'submission.png');
    formData.append(
      'selectedGeneration',
      dataURItoBlob(img),
      'selected_generation.png'
    );

    fetch(new URL('/event/fnac70/submit', import.meta.env.VITE_API_URL), {
      headers: {
        authorization: `Bearer ${keycloak.token}`,
      },
      body: formData,
      method: 'POST',
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) return;

        setHasAlreadySubmitted(true);

        setSubmissionImg(data.submissionImgUrl);

        setIsWelcomeModalOpen(false);
        setTutorialModalStep(0);
        setIsSubmissionModalOpen(false);
        setIsThankModalOpen(true && !fnacStopped);
      });
  };

  const dropImageNoop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
  };

  if (isFnacUser && loading) return;

  return (
    <>
      {(isFnacUser || isFnacModerator || isFnacDemo) && fnacStopped && <FnacEndedModal />}

      <WelcomeModal
        isAllowedToParticipate={isFnacAdherent || isFnacDemo || isFnacModerator}
        isOpen={isWelcomeModalOpen}
        onClose={handleOnWelcomeModalClose}
      />
      <TutorialModal
        isOpen={tutorialModalStep !== 0}
        step={tutorialModalStep}
        onNext={handleOnTutorialModalNext}
      />
      <SubmissionModal
        isOpen={isSubmissionModalOpen}
        img={img}
        onSubmit={handleOnSubmissionDone}
        onClose={handleOnSubmissionModalClose}
      />
      <ThankModal
        isOpen={isThankModalOpen}
        img={submissionImg}
        setIsThankModalOpen={setIsThankModalOpen}
      />

      <BeinkPanelNext
        isFnacAdherent={isFnacAdherent}
        isFnacUser={isFnacUser}
        onImport={importImage}
        onGetSelection={exportImage}
        hasObjectSelected={hasObjectSelected}
        onSubmissionStart={handleOnSubmissionStart}
        hasAlreadySubmitted={hasAlreadySubmitted}
      />

      <div
        className='relative overflow-hidden'
        onDrop={isFnacUser || isFnacDemo ? dropImageNoop : dropImage}
        onDragEnter={() => {
          setIsDragOver(true);
        }}
        onDragLeave={() => {
          setIsDragOver(false);
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}>
        <div
          className={`max-sm:hidden pointer-events-none absolute inset-0 right-0 z-90 p-6 sm:right-72 lg:right-96 ${
            isDragOver ? 'opacity-1' : 'opacity-0'
          } transition-opacity`}>
          <div className='grid h-full w-full place-content-center rounded-lg bg-grey-700/80 backdrop-blur-sm'>
            <span className='text-balance text-xl text-white'>
              {isFnacUser || isFnacDemo
                ? t('Whiteboard.NoImageImportFnac')
                : t('Whiteboard.DragFiles')}
            </span>
          </div>
        </div>

        <div className='toolbar'>
          {toolsList.map((item) => (
            <button
              key={item.name}
              onClick={
                item.onClick
                  ? item.onClick
                  : (e) => {
                      // Only fire the changeTool callback on when clicked on the tool buton, not
                      // the size changer
                      // @ts-ignore
                      const targetTagName = e.target.tagName.toLowerCase();
                      if (
                        targetTagName === 'svg' ||
                        targetTagName === 'button'
                      ) {
                        changeTool(item.name);
                      }
                    }
              }
              className={`${item.name} ${tool === item.name ? 'active' : ''}`}
              title={item.title}
              disabled={item.name === 'crop' ? !hasImageSelected : false}>
              <item.icon />

              {'pen' === item.name ? (
                <div className='pen-size'>
                  <div
                    className={penSize === PenSize.sm ? 'active sm' : 'sm'}
                    onClick={() => changePenSize(PenSize.sm)}></div>
                  <div
                    className={penSize === PenSize.md ? 'active md' : 'md'}
                    onClick={() => changePenSize(PenSize.md)}></div>
                  <div
                    className={penSize === PenSize.lg ? 'active lg' : 'lg'}
                    onClick={() => changePenSize(PenSize.lg)}></div>
                  <div
                    className={penSize === PenSize.xlg ? 'active xlg' : 'xlg'}
                    onClick={() => changePenSize(PenSize.xlg)}></div>
                  <div
                    className={
                      penSize === PenSize.xxlg ? 'active xxlg' : 'xxlg'
                    }
                    onClick={() => changePenSize(PenSize.xxlg)}></div>
                </div>
              ) : (
                ''
              )}

              {'eraser' === item.name ? (
                <div className='eraser-size'>
                  <div
                    className={
                      eraserSize === EraserSize.sm ? 'active sm' : 'sm'
                    }
                    onClick={() => changeEraserSize(EraserSize.sm)}></div>
                  <div
                    className={
                      eraserSize === EraserSize.md ? 'active md' : 'md'
                    }
                    onClick={() => changeEraserSize(EraserSize.md)}></div>
                  <div
                    className={
                      eraserSize === EraserSize.lg ? 'active lg' : 'lg'
                    }
                    onClick={() => changeEraserSize(EraserSize.lg)}></div>
                  <div
                    className={
                      eraserSize === EraserSize.xlg ? 'active xlg' : 'xlg'
                    }
                    onClick={() => changeEraserSize(EraserSize.xlg)}></div>
                  <div
                    className={
                      eraserSize === EraserSize.xxlg ? 'active xxlg' : 'xxlg'
                    }
                    onClick={() => changeEraserSize(EraserSize.xxlg)}></div>
                </div>
              ) : (
                ''
              )}
            </button>
          ))}

          {
            // Disable the import image button for Fnac users
            isFnacUser || isFnacDemo ? (
              <button title={t('Whiteboard.NoImageImportFnac')} disabled>
                <Image />
              </button>
            ) : (
              <button
                onClick={() => {
                  if (uploadInput.current) uploadInput.current.click();
                }}
                title={t('Whiteboard.Tools.uploadImage')}>
                <Image />
              </button>
            )
          }

          <button
            className='download-selection'
            title={t('Whiteboard.Tools.downloadImage')}
            onClick={downloadImage}
            disabled={!hasObjectSelected}>
            <Download />
          </button>

          <Shortcuts />

          <button
            className='currentColor'
            title={t('Whiteboard.Tools.colorPicker')}
            onClick={() => setOpenColorpicker(!openColorpicker)}>
            <div style={{ backgroundColor: color }}></div>
          </button>

          {openColorpicker && (
            <div className='colorpicker'>
              <Colorful
                color={color}
                disableAlpha
                onChange={(color) => {
                  changeColor(color.hex);
                }}
              />
            </div>
          )}

          {
            // Forbid image upload for Fnac users
            !isFnacUser && !isFnacDemo && (
              <input
                className='sr-only'
                ref={uploadInput}
                type='file'
                onChange={uploadImage}
              />
            )
          }
        </div>

        <div className='toolbar secondary'>
          <button
            title={t('Whiteboard.Tools.undo')}
            disabled={historyUndo.length === 0}
            onClick={() => undoAction()}>
            <Undo2 />
          </button>

          <button
            title={t('Whiteboard.Tools.redo')}
            disabled={historyRedo.length === 0}
            onClick={() => redoAction()}>
            <Redo />
          </button>

          <button
            title={t('Whiteboard.Tools.sendToBack')}
            disabled={!hasObjectSelected}
            onClick={() => bringSelectionToBack()}>
            <SendToBack />
          </button>

          <button
            title={t('Whiteboard.Tools.bringToFront')}
            disabled={!hasObjectSelected}
            onClick={() => bringSelectionToFront()}>
            <BringToFront />
          </button>

          <button
            title={t('Whiteboard.Tools.deleteSelected')}
            disabled={!hasObjectSelected}
            onClick={() => removeSelectedObject()}>
            <Trash />
          </button>
        </div>

        <div className='zoomInfo' onClick={resetZoom}>
          {Math.round(zoom * 100)}%
        </div>

        <div
          ref={fabricCanvas}
          tabIndex={0}
          {...bind()}
          style={{ touchAction: 'none' }}>
          <FabricJSCanvas onReady={onReady} />
        </div>
      </div>
    </>
  );
}

// Parse Fnac end date (YYYYmmdd format) to js date
function parseFnacPlusEndDate(str: string) {
  if (!str) return;

  if (str.length !== 8) {
    return;
  }

  const y = Number(str.substring(0, 4)),
    m = Number(str.substring(4, 6)),
    d = Number(str.substring(6, 8));
  const D = new Date(y, m - 1, d);

  return D.getFullYear() == y && D.getMonth() == m - 1 && D.getDate() == d
    ? D
    : undefined;
}
