import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ModalProps {
  isOpen: boolean;
  onSubmit: (comment: string) => (e: React.MouseEvent) => void;
  onClose: () => void;
}

type Option = {
  id: number;
  label: string;
  value: string;
};

export const ReportModal: React.FC<ModalProps> = ({
  isOpen,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation(['fnac']);

  const OPTIONS = [
    { id: 0, label: t('fnac:ReportOptions.Select'), value: 'Value 0' },
    {
      id: 1,
      label: t('fnac:ReportOptions.Nudity'),
      value: 'nudity-sexual',
    },
    {
      id: 2,
      label: t('fnac:ReportOptions.Violence'),
      value: 'violence-brutality',
    },
    { id: 3, label: t('fnac:ReportOptions.Hatred'), value: 'hatred' },
    {
      id: 4,
      label: t('fnac:ReportOptions.Terrorism'),
      value: 'terrorism-extremism',
    },
    {
      id: 5,
      label: t('fnac:ReportOptions.Fake'),
      value: 'fake-news',
    },
    {
      id: 6,
      label: t('fnac:ReportOptions.Shocking'),
      value: 'shocking-disgusting',
    },
    {
      id: 7,
      label: t('fnac:ReportOptions.Privacy'),
      value: 'privacy-violation',
    },
    { id: 8, label: t('fnac:ReportOptions.Spam'), value: 'ad-spam' },
    { id: 9, label: t('fnac:ReportOptions.Other'), value: 'other' },
  ];

  const [selectedOption, setSelectedOption] = useState<string>('');
  const [freeInput, setFreeInput] = useState<string>('');

  const handleOnChangeSelectOption = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    e.preventDefault();

    setSelectedOption(e.currentTarget.value);
  };

  const handleOnChangeFreeInput = (e: React.FormEvent<HTMLTextAreaElement>) => {
    e.preventDefault();

    setFreeInput(e.currentTarget.value);
  };

  if (!isOpen) return null;

  return (
    <div
      className={`fixed inset-0 z-100 flex items-center justify-center border border-hidden bg-fnac-gray bg-opacity-80 p-4 backdrop-blur-sm ${
        isOpen ? 'no-doc-scroll' : ''
      }`}>
      <div className='beink-radial-gradient w-full max-w-3xl rounded-3xl px-24 py-16'>
        <div className='flex flex-col items-center space-y-4 text-center'>
          <h2 className='mb-4 text-3xl uppercase'>
            <span className='font-jakarta font-extrabold text-white'>
              {t('fnac:ReportModal.Title1')}
            </span>{' '}
            <span className='font-ccsign font-bold text-fnac-mustard'>
              {t('fnac:ReportModal.Title2')}
            </span>
          </h2>
          <p className='mb-4 text-white'>
            {t('fnac:ReportModal.SubmissionWarning')}
          </p>
          <p className='mb-4 text-white'>
            {t('fnac:ReportModal.NoOveruseWarning')}
          </p>
          <p className='mb-4 text-white'>
            {t('fnac:ReportModal.ContributionThanks')}
          </p>

          <select
            className='w-full rounded-md border border-white bg-transparent px-8 py-4 font-jakarta font-bold text-white'
            onChange={handleOnChangeSelectOption}>
            {OPTIONS.map((opt: Option) => (
              <option
                key={opt.id}
                value={opt.value}
                className='text-fnac-black'>
                {opt.label}
              </option>
            ))}
          </select>

          <textarea
            className='w-full rounded-md border border-white bg-transparent px-8 py-4 font-jakarta font-bold text-white'
            placeholder={t('fnac:ReportModal.Placeholder')}
            onChange={handleOnChangeFreeInput}
          />

          <div className='flex items-center justify-center space-x-4 p-6'>
            <button
              onClick={onClose}
              className='rounded-md border border-white bg-transparent px-8 py-4 font-jakarta font-bold text-white'>
              {t('fnac:ReportModal.Cancel')}
            </button>
            <button
              onClick={onSubmit(`${selectedOption} ${freeInput}`.trim())}
              className='rounded-md border border-fnac-mustard bg-fnac-mustard px-8 py-4 font-jakarta font-bold text-fnac-black'>
              {t('fnac:ReportModal.Confirm')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
