import {
  Dispatch,
  SetStateAction,
  useState,
  createContext,
  useContext,
} from 'react';
import { GenerationFromAPI } from '../Results/Results';
import { BeinkMode } from '../../../../types';

type PanelContextType = {
  isPanelOpen: boolean;
  setIsPanelOpen: Dispatch<SetStateAction<boolean>>;
  showHelp: boolean;
  setShowHelp: Dispatch<SetStateAction<boolean>>;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  currentMode: BeinkMode;
  setCurrentMode: Dispatch<SetStateAction<BeinkMode>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  inProgressGeneration: GenerationFromAPI | null;
  setInProgressGeneration: Dispatch<SetStateAction<GenerationFromAPI | null>>;
  showAllGenerations: boolean;
  setShowAllGenerations: Dispatch<SetStateAction<boolean>>;
  isAllGenerationsOpen: boolean;
  setIsAllGenerationsOpen: Dispatch<SetStateAction<boolean>>;
};

export const PanelContext = createContext<PanelContextType | undefined>(
  undefined
);

// Création de la fonction usePanelContext pour accéder au contexte
export const usePanelContext = () => {
  const context = useContext(PanelContext);
  if (context === undefined) {
    throw new Error(
      "usePanelContext doit être utilisé à l'intérieur de PanelProvider"
    );
  }
  return context;
};

export const PanelProvider = ({ children }: { children: React.ReactNode }) => {
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentMode, setCurrentMode] = useState<BeinkMode>(BeinkMode.Imagine);
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [inProgressGeneration, setInProgressGeneration] =
    useState<GenerationFromAPI | null>(null);
  const [showAllGenerations, setShowAllGenerations] = useState<boolean>(false);
  const [isAllGenerationsOpen, setIsAllGenerationsOpen] =
    useState<boolean>(false);

  return (
    <PanelContext.Provider
      value={{
        isPanelOpen,
        setIsPanelOpen,
        showHelp,
        setShowHelp,
        error,
        setError,
        setLoading,
        loading,
        currentMode,
        setCurrentMode,
        inProgressGeneration,
        setInProgressGeneration,
        showAllGenerations,
        setShowAllGenerations,
        isAllGenerationsOpen,
        setIsAllGenerationsOpen,
      }}>
      {children}
    </PanelContext.Provider>
  );
};
