import { useTranslation } from 'react-i18next';
import { BeinkMode, UserRole } from '../../../types';
import {
  faCircleQuestion,
  faPencil,
  faMagicWandSparkles,
  faTimes,
  faAnglesDown,
} from '@fortawesome/free-solid-svg-icons';
import SimpleButton from '../../lib/SimpleButton';
import { useKeycloak } from '@react-keycloak/web';
import { usePanelContext } from './context/usePanelContext';
import { isMobile } from '../../../helpers/device';

const PanelNavigation = function () {
  const { t } = useTranslation(['translation', 'fnac', 'panel']);
  const {
    showHelp,
    setShowHelp,
    currentMode,
    setCurrentMode,
    isPanelOpen,
    setIsPanelOpen,
  } = usePanelContext();

  const { keycloak } = useKeycloak();
  const userRoles: string[] = keycloak.realmAccess?.roles || [];
  const inpaintAllowed = userRoles.includes(UserRole.Inpaint);

  return (
    <div
      className={`relative ${isPanelOpen ? 'w-full' : 'flex-grow items-center md:p-2'}`}>
      <div
        className={`flex w-full items-center gap-3 ${
          isPanelOpen
            ? 'w-full flex-grow justify-between md:p-2 md:py-2'
            : 'flex-col items-center justify-center'
        }`}>
        <div
          className={`flex gap-2 place-self-center rounded-2xl bg-d-surface-bg-tertiary p-1.5 ${
            isPanelOpen
              ? 'flex-row'
              : 'flex-grow flex-row items-center justify-center md:flex-col'
          }`}>
          {/* Premier bouton */}
          <div>
            <SimpleButton
              width={isPanelOpen ? '' : '48px'}
              height={isPanelOpen ? '' : '48px'}
              showLabel={isPanelOpen}
              size='md'
              leftIcon={faPencil}
              state={currentMode === BeinkMode.Imagine ? 'active' : 'inactive'}
              onClick={() => {
                setCurrentMode(BeinkMode.Imagine);
                setIsPanelOpen(true);
              }}>
              {t('panel:Modes.Imagine')}
            </SimpleButton>
          </div>

          {/* Deuxième bouton */}
          {inpaintAllowed && (
            <div>
              <SimpleButton
                width={isPanelOpen ? '' : '48px'}
                height={isPanelOpen ? '' : '48px'}
                showLabel={isPanelOpen}
                leftIcon={faMagicWandSparkles}
                state={
                  currentMode === BeinkMode.Inpaint ? 'active' : 'inactive'
                }
                onClick={() => {
                  setCurrentMode(BeinkMode.Inpaint);
                  setIsPanelOpen(true);
                }}>
                {t('panel:Modes.Inpainting')}
              </SimpleButton>
            </div>
          )}
        </div>

        {/* Boutons d'action à droite */}
        {isPanelOpen && (
          <div className='flex gap-2'>
            <SimpleButton
              leftIcon={showHelp ? faTimes : faCircleQuestion}
              variant='tertiary'
              round
              size='squareMD'
              onClick={() => {
                setShowHelp((prev) => !prev);
              }}
            />
            {/* Nouveau bouton à droite du bouton Aide */}
            {isPanelOpen && !isMobile() && (
              <SimpleButton
                hiddenMobile={true}
                leftIcon={faAnglesDown}
                round
                variant='secondary'
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PanelNavigation;
