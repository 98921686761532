import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import { ProtectedLayout } from '../helpers/ProtectedLayout';
import {
  teamManagementEnabled,
  fnacEnabled,
  fnacStopped,
} from '../helpers/FeatureFlags';
import NotFoundPage from './NotFound';
import TeamManagementPage from './TeamManagement';
import HomePage from './Home';
import WhiteboardPage from './Whiteboard';

import BeinkLayout from '../helpers/BeinkLayout';
import { BeinkLayout as BeinkLayoutV2 } from '../v2/components/layout/BeinkLayout';
import { WelcomePage } from '../v2/pages/WelcomePage';
import { HomePage as ContestPage } from '../v2/pages/HomePage';
import { MyCreationsPage as HomePageV2 } from '../v2/pages/MyCreationsPage';
import { useKeycloak } from '@react-keycloak/web';
import { changeLanguage } from 'i18next';
import FnacEnd from '../v2/pages/FnacEnd';
import Creator from './Creator';

const router = ({
  isFnacUser,
  isFnacDemo,
  isFnacModerator,
}: {
  isFnacUser: boolean;
  isFnacDemo: boolean;
  isFnacModerator: boolean;
}) =>
  createBrowserRouter(
    createRoutesFromElements(
      <>
        {fnacEnabled && !fnacStopped && (
          <Route path='/fnac' element={<WelcomePage />} />
        )}

        {isFnacUser || isFnacDemo || isFnacModerator ? (
          <Route path='/' element={<ProtectedLayout />}>
            <Route element={<BeinkLayoutV2 fnacStopped={fnacStopped} />}>
              <Route
                path='/gallery'
                element={
                  fnacStopped ? (
                    <FnacEnd />
                  ) : (
                    <ContestPage
                      isFnacModerator={isFnacModerator}
                      isFnacDemo={isFnacDemo}
                    />
                  )
                }
              />
              <Route
                path='/'
                element={<HomePageV2 isFnacUserOrDemo={true} />}
              />
              <Route path='*' element={<NotFoundPage />}></Route>
            </Route>
            <Route
              path='/creator'
              element={
                <WhiteboardPage
                  isFnacUser={isFnacUser}
                  isFnacDemo={isFnacDemo}
                  isFnacModerator={isFnacModerator}
                />
              }
            />
          </Route>
        ) : (
          <Route path='/' element={<ProtectedLayout />}>
            <Route element={<BeinkLayout />}>
              <Route index element={<HomePage />} />
              {teamManagementEnabled && (
                <Route path='/team' element={<TeamManagementPage />} />
              )}
              <Route path='*' element={<NotFoundPage />}></Route>
            </Route>
            <Route path='/creator' element={<Creator />} />
          </Route>
        )}

        <Route path='*' element={<NotFoundPage />}></Route>
      </>
    )
  );

const Router = () => {
  const { initialized, keycloak } = useKeycloak();
  if (!initialized) return;

  const userRoles: string[] = keycloak.realmAccess?.roles || [];
  const isFnacUser = userRoles.includes('beink-fnac-user');
  const isFnacDemo = userRoles.includes('beink-fnac-demo');
  const isFnacModerator = userRoles.includes('beink-fnac-moderator');

  if (isFnacUser || isFnacDemo || isFnacModerator) {
    changeLanguage('fr');
  }

  return (
    <RouterProvider
      router={router({ isFnacUser, isFnacDemo, isFnacModerator })}
    />
  );
};

export default Router;
