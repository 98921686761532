import { DesignStyle } from '../../../../types';
import { faPalette } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import TabHelp from '../Tabs/TabHelp';
import TextArea from '../../../lib/TextArea';
import Dropdown from '../../../lib/Dropdown';
import { usePanelContext } from '../context/usePanelContext';
import { usePanelInputContext } from '../context/usePanelInputContext';

export const Imagine = () => {
  const { i18n } = useTranslation(['panel']);
  const { showHelp, setShowHelp, setError } = usePanelContext();
  const {
    graphicStyle,
    setGraphicStyle,
    imagineInputText,
    setImagineInputText,
  } = usePanelInputContext();
  const { t } = useTranslation(['panel']);

  const SUGGESTIONS: Record<DesignStyle, string> = {
    [DesignStyle.Industrial]: t('panel:Imagine.DesignStyle.Industrial'),
    [DesignStyle.Line]: t('panel:Imagine.DesignStyle.Line'),
    [DesignStyle.Davinci]: t('panel:Imagine.DesignStyle.Davinci'),
    [DesignStyle.HandSketch]: t('panel:Imagine.DesignStyle.HandSketch'),
    [DesignStyle.FashionDrawing]: t('panel:Imagine.DesignStyle.FashionDrawing'),
    [DesignStyle.Scientific]: t('panel:Imagine.DesignStyle.Scientific'),
    [DesignStyle.Photorealistic]: t('panel:Imagine.DesignStyle.Photorealistic'),
  };

  return (
    <div className='w-full flex-col-reverse gap-3 excalidraw:flex-col'>
      {/* HELP */}
      {showHelp && (
        <TabHelp
          setShowHelp={setShowHelp}
          steps={[
            t('panel:Imagine.help.step1'),
            t('panel:Imagine.help.step2'),
            t('panel:Imagine.help.step3'),
          ]}
        />
      )}

      <div className='flex flex-col gap-6 rounded-2xl'>
        <TextArea
          label={t('panel:Imagine.promptLabel')}
          id='prompt'
          rows={4}
          placeholder={t('panel:Imagine.promptPlaceholder')}
          value={imagineInputText}
          onChange={(e) => {
            setImagineInputText(e.target.value);
            setError('');
          }}
        />

        <Dropdown
          key={i18n.language}
          options={Object.entries(SUGGESTIONS).map(([value, label]) => ({
            label,
            value,
          }))}
          initialValue={graphicStyle && (SUGGESTIONS[graphicStyle] as string)}
          label={t('panel:Imagine.designStyleLabel')}
          icon={faPalette}
          onChange={(value) => {
            setGraphicStyle(value as DesignStyle);
            setError('');
          }}
          placeholder={t('panel:Imagine.designStylePlaceholder')}
        />
      </div>
    </div>
  );
};
