import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Generation } from '../types';
import GenerationImage from './GenerationImage';
import { faX } from '@fortawesome/free-solid-svg-icons';

type GenerationInfoPanelProps = {
  generation: Generation;
  timeSinceCreationString: string;
  setIsInfoPanelOpen: (isOpen: boolean) => void;
  isFnacUserOrDemo?: boolean;
};

const GenerationInfoPanel = ({
  generation,
  timeSinceCreationString,
  setIsInfoPanelOpen,
  isFnacUserOrDemo,
}: GenerationInfoPanelProps) => {
  const { generatedImagesUrl } = generation;

  return (
    <div className='fixed inset-0 z-100 flex h-dvh w-screen items-center justify-end bg-grey-700 bg-opacity-25 backdrop-blur-md'>
      <div className='mx-2 flex h-[98vh] w-full flex-col gap-6 rounded-xl bg-grey-600 p-6 sm:mr-6 sm:w-[600px]'>
        <div className='images grid grid-cols-2 gap-6'>
          {generatedImagesUrl.map((url, index) => {
            if (isFnacUserOrDemo) {
              return (
                <a
                  key={index}
                  className='aspect-[16/11] w-full'
                  href={url}
                  target='blank'>
                  <img
                    className='h-full w-full cursor-pointer rounded-xl object-cover'
                    src={url}
                    alt=''
                  />
                </a>
              );
            }
            return (
              <GenerationImage
                imagePath={url}
                className='h-full w-full cursor-pointer rounded-xl object-cover'
                key={index}
                alt=''
                linkClassName='w-full aspect-[16/11]'
                withLink={true}
              />
            );
          })}
        </div>

        <div className='flex flex-grow flex-col justify-between gap-6'>
          <div>
            <div className='hidden items-center gap-2'>
              <FontAwesomeIcon icon={faClock} />
              <p className='text-sm text-white'>{timeSinceCreationString}</p>
            </div>
            <div className='prompt rounded-2xl bg-fnac-card-default p-4 font-normal'>
              <p className='font-ccsign text-xl text-white'>
                {generation.input}
              </p>
            </div>
          </div>

          <div
            className='m-5 flex h-[44px] w-[44px] cursor-pointer items-center justify-center self-center rounded-full bg-white text-fnac-black'
            onClick={() => {
              setIsInfoPanelOpen(false);
            }}>
            <FontAwesomeIcon className='text-black' icon={faX} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerationInfoPanel;
