import { TextareaHTMLAttributes } from 'react';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
}

const TextArea = ({ label, ...props }: TextAreaProps) => {
  return (
    <div className='flex flex-col gap-4'>
      {label && (
        /* TODO: extract label component */
        <div className='font-jakarta text-sm text-d-text-2 excalidraw:pl-2'>
          {label}
        </div>
      )}
      <textarea
        {...props}
        className='w-full resize-none appearance-none rounded-xl border-none bg-d-surface-bg-secondary p-3 text-sm font-medium text-d-text-neg shadow-none focus:border-none focus:text-d-input-text-focus focus:ring-green-400'
        rows={4}
      />
    </div>
  );
};

export default TextArea;
