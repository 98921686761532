import { Eye, EyeOff } from "lucide-react";
import { useState } from "react";

type ExpandableCardProps = {
  initialExpand: boolean;
  genId: string;
  date: string;
  images: string[];
  index: number;

  handleSelectCard: ({
    genId,
    date,
    img,
  }: {
    genId: string;
    date: string;
    img: string;
  }) => void;
  importFnct: (img: string) => void;
};

export const ExpandableCard: React.FC<ExpandableCardProps> = ({
  initialExpand = false,
  genId,
  date,
  images,
  index,

  handleSelectCard,
  importFnct,
}: ExpandableCardProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(initialExpand);
  const [selectedImg, setSelectedImg] = useState<string>("");

  return (
    <div className={`bg-white  rounded-2xl p-4 w-full flex flex-col ${isExpanded ? "bg-opacity-15" : "bg-opacity-5"}`}>
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-2 gap-2 font-jakarta">
          #
          <span className="text-base text-white font-jakarta">
            {index}
          </span>
        </div>
        {isExpanded ? (
          <EyeOff
            width={14}
            height={14}
            className="text-white cursor-pointer"
            onClick={() => setIsExpanded(false)}
          />
        ) : (
          <Eye
            width={14}
            height={14}
            className="text-white cursor-pointer"
            onClick={() => setIsExpanded(true)}
          />
        )}
      </div>
      {isExpanded && (
        <div className="grid grid-cols-2 gap-3 pt-4 max-w-[400px] w-full justify-center place-self-center">
          {images.map((img, index) => (
            <div
              className={`cursor-pointer ${selectedImg === img ? "border-4 border-fnac-mustard" : ""
                }`}
              key={img}
              onClick={() => {
                const shouldSelectAndImport = selectedImg !== img;
                setSelectedImg(shouldSelectAndImport ? img : "");
                handleSelectCard({
                  genId,
                  date,
                  img: shouldSelectAndImport ? img : "",
                });
                shouldSelectAndImport && importFnct(img);
              }}
            >
              <img
                id={"generation-" + genId + "-" + index}
                className="w-full h-auto transition-opacity rounded-lg hover:opacity-75"
                src={img}
                crossOrigin="anonymous"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
