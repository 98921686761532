import {
  faArrowPointer,
  faHourglass,
  faMagicWandSparkles,
  faMessage,
  faPalette,
} from '@fortawesome/free-solid-svg-icons';

import { useCreatorContext } from '../../../pages/Creator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAppContext from '../../../hooks/useAppContext';
import useGenerate from '../../../hooks/useGenerate';
import { BeinkMode } from '../../../types';
import useInpaint from '../../../hooks/useInpaint';

import { HTMLProps } from 'react';

import { usePanelContext } from './context/usePanelContext';
import { useTranslation } from 'react-i18next';
import SimpleButton from '../../lib/SimpleButton';
import { usePanelInputContext } from './context/usePanelInputContext';

type GenerateButtonProps = {
  className?: HTMLProps<HTMLElement>['className'];
  onGenerationDone?: () => void;
  isPanelOpen: boolean;
};

const GenerateButton = ({
  onGenerationDone,
  isPanelOpen,
}: GenerateButtonProps) => {
  const { generateImage } = useGenerate();
  const { inpaintImage } = useInpaint();
  const { t } = useTranslation(['panel']);

  const {
    hasObjectSelected,
    hasImageSelected,
    hasDrawingSelected,
    exportSelectedElementsAsImage,
    exportForInpainting,
  } = useCreatorContext();
  const { currentMode, setLoading, loading } = usePanelContext();

  const { inpaintInputText, imagineInputText, graphicStyle } =
    usePanelInputContext();
  const { generationsLeft } = useAppContext();

  const isButtonDeactivated = () => {
    if (!hasObjectSelected || loading || generationsLeft <= 0) {
      return true;
    }
    if (currentMode === BeinkMode.Inpaint) {
      if (!inpaintInputText || !hasImageSelected || !hasDrawingSelected) {
        return true;
      }
    }
    if (currentMode === BeinkMode.Imagine) {
      if (!imagineInputText || graphicStyle === null) {
        return true;
      }
    }
    return false;
  };

  const getButtonContent = () => {
    if (!isPanelOpen) {
      if (loading) {
        return <FontAwesomeIcon icon={faHourglass} />;
      }
      if (currentMode === BeinkMode.Inpaint) {
        if (!inpaintInputText) {
          return <FontAwesomeIcon icon={faMessage} />;
        }
        if (!hasImageSelected || !hasDrawingSelected) {
          return <FontAwesomeIcon icon={faArrowPointer} />;
        }
      } else if (currentMode === BeinkMode.Imagine) {
        if (!imagineInputText) {
          return <FontAwesomeIcon icon={faMessage} />;
        }
        if (graphicStyle === null) {
          return <FontAwesomeIcon icon={faPalette} />;
        }
      }
      if (!hasObjectSelected) {
        return <FontAwesomeIcon icon={faArrowPointer} />;
      }
      if (generationsLeft <= 0) {
        return <FontAwesomeIcon icon={faHourglass} />;
      }
      return <FontAwesomeIcon icon={faMagicWandSparkles} />;
    }

    if (currentMode === BeinkMode.Inpaint) {
      if (!inpaintInputText) {
        return (
          <div className='flex items-center gap-2'>
            {t('panel:GenerateButton.disabled.noInputText')}
            <FontAwesomeIcon icon={faMessage} />
          </div>
        );
      }
      if (!hasImageSelected) {
        return (
          <div className='flex items-center gap-2'>
            {t('panel:GenerateButton.disabled.noImageSelected')}
            <FontAwesomeIcon icon={faArrowPointer} />
          </div>
        );
      }
      if (!hasDrawingSelected) {
        return (
          <div className='flex items-center gap-2'>
            {t('panel:GenerateButton.disabled.noContourSelected')}
            <FontAwesomeIcon icon={faArrowPointer} />
          </div>
        );
      }
    }

    if (currentMode === BeinkMode.Imagine) {
      if (!imagineInputText) {
        return (
          <div className='flex items-center gap-2'>
            {t('panel:GenerateButton.disabled.noInputText')}
            <FontAwesomeIcon icon={faMessage} />
          </div>
        );
      }
      if (graphicStyle === null) {
        return (
          <div className='flex items-center gap-2'>
            {t('panel:GenerateButton.disabled.noStyleSelected')}
            <FontAwesomeIcon icon={faPalette} />
          </div>
        );
      }
    }

    if (!hasObjectSelected) {
      return (
        <div className='flex items-center gap-2'>
          {t('panel:GenerateButton.disabled.noDrawingSelected')}
          <FontAwesomeIcon icon={faArrowPointer} />
        </div>
      );
    }

    if (loading) {
      return (
        <div className='flex items-center gap-2'>
          {t('panel:GenerateButton.loading')}
          <FontAwesomeIcon icon={faHourglass} />
        </div>
      );
    }

    if (generationsLeft <= 0) {
      return <>{t('panel:GenerateButton.disabled.noGenerationsLeft')}</>;
    }

    return (
      <div className='flex items-center gap-2'>
        {t('panel:GenerateButton.enabled')}
        <FontAwesomeIcon icon={faMagicWandSparkles} />
      </div>
    );
  };

  const handleGenerate = async () => {
    if (loading) return;

    const dataImg = await exportSelectedElementsAsImage();
    if (dataImg && imagineInputText && graphicStyle) {
      setLoading(true);
      onGenerationDone && onGenerationDone();
      await generateImage({
        input: imagineInputText,
        graphicStyle,
        dataImg,
      });
      setLoading(false);
    }
  };

  const handleInpaint = async () => {
    if (loading) return;

    const [dataImg, maskImg] = await exportForInpainting();
    if (dataImg && maskImg && inpaintInputText) {
      setLoading(true);
      onGenerationDone && onGenerationDone();
      await inpaintImage({
        prompt: inpaintInputText,
        scribble: dataImg,
        mask: maskImg,
      });
      setLoading(false);
    }
  };

  return (
    <SimpleButton
      width={isPanelOpen ? '100%' : '48px'}
      height={isPanelOpen ? '' : '48px'}
      variant='generateButton'
      state={isButtonDeactivated() ? 'disabled' : 'active'}
      disabled={isButtonDeactivated()}
      iconSize='16px'
      size='md'
      onClick={() => {
        if (currentMode === BeinkMode.Imagine) {
          handleGenerate();
        } else {
          handleInpaint();
        }
      }}>
      {getButtonContent()}
    </SimpleButton>
  );
};

export default GenerateButton;
