import {
  faAnglesDown,
  faAnglesRight,
  faHouse,
} from '@fortawesome/free-solid-svg-icons';
import SimpleButton from '../../lib/SimpleButton';
import { usePanelContext } from './context/usePanelContext';
//import { isMobile } from '../../../helpers/device';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type PanelHeaderProps = {
  visible: boolean;
};

const PanelHeader = ({ visible }: PanelHeaderProps) => {
  const context = usePanelContext();

  if (!context) {
    throw new Error('usePanelContext must be used within a PanelProvider');
  }

  const { isPanelOpen, setIsPanelOpen } = context;

  if (!visible) return null;

  const isFull = isPanelOpen;

  return (
    <div
      className={`top-0 flex ${
        isFull
          ? 'w-full flex-grow flex-row items-center justify-between p-0 md:flex-grow-0'
          : 'flex-col items-center justify-center gap-4 p-2'
      }`}>
      {isFull && (
        <SimpleButton
          href='/'
          variant='tertiary'
          round
          iconSize='16px'
          size='squareMD'
          leftIcon={faHouse}
        />
      )}

      {isFull && <img src='/logo-white.svg' alt='Logo' className='' />}

      {/* Bouton pour ouvrir/fermer le panneau */}
      <div>
        <SimpleButton
          round
          theme='flat'
          variant='tertiary'
          size='squareMD'
          iconSize='16px'
          onClick={() => {
            console.log('Toggle isPanelOpen');
            setIsPanelOpen((prev) => !prev);
          }}>
          {/* Icône verticale (pour mobile, affichée en dessous de md) */}
          <FontAwesomeIcon
            icon={faAnglesDown}
            className={`block md:hidden ${
              isPanelOpen ? 'rotate-0' : 'rotate-180'
            }`}
            style={{ width: '16px', height: '16px' }}
          />

          {/* Icône horizontale (pour desktop, affichée à partir de md) */}
          <FontAwesomeIcon
            icon={faAnglesRight}
            className={`hidden md:block ${
              isPanelOpen ? 'rotate-180' : 'rotate-0'
            }`}
            style={{ width: '16px', height: '16px' }}
          />
        </SimpleButton>
      </div>
    </div>
  );
};

export default PanelHeader;
