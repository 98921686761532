import { Check, Flag, Info } from "lucide-react";
import { useState } from "react";
import InfoPanel from "./InfoPanel";
import { CardType } from "./Gallery";

export type ModeratorCardProps = {
  card: CardType;
  isVoteDisabled: boolean;
  isFnacModerator: boolean;
  onUnflagHandler: (e: React.MouseEvent) => void;
  onBlockHandler: (e: React.MouseEvent) => void;
  onLike: (id: string) => void;
  onShareHandler: (e: React.MouseEvent) => void;
  onFlag: (id: string) => void;
};

export const ModeratorCard: React.FC<ModeratorCardProps> = (props: ModeratorCardProps) => {
  const { card, onUnflagHandler, onBlockHandler, } = props;
  const { id, src } = card;
  const [showInfoPanel, setShowInfoPanel] = useState(false);


  return (
    <div className="flex flex-col w-full p-4 border border-hidden rounded-2xl bg-fnac-card-default relative">
      {showInfoPanel &&
        <InfoPanel setShowInfoPanel={setShowInfoPanel}
          {...props} ></InfoPanel>
      }
      <img src={src} className="w-full aspect-[16/10] object-cover rounded-lg mb-2 border-r border-fnac-black" />

      <div className="flex justify-between items-center">
        <div className="flex flex-col space-y-4 justify-between w-full">
          <div className="flex flex-col xs:flex-col lg:flex-col xl:flex-col 2xl:flex-row space-x-2 py-2 w-full justify-between items-center gap-4">
            <button onClick={onUnflagHandler} value={id} className="flex flex-row gap-2 items-center px-4 py-2 rounded-xl bg-fnac-mustard text-fnac-black font-bold justify-center w-full flex-grow">
              Valider <Check width={16} height={16} />
            </button>
            <button onClick={onBlockHandler} value={id} className="flex flex-row gap-2 justify-center items-center px-4 py-2 rounded-xl border-red border border-solid text-red w-full flex-grow">
              Rejeter <Flag width={16} height={16} />
            </button>
            <button onClick={() => {
              setShowInfoPanel(true);
            }} value={id}>
              <Info width={16} height={16} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
