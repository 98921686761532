import { Dispatch, SetStateAction, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

type TabHelpProps = {
  steps: string[];
  setShowHelp: Dispatch<SetStateAction<boolean>>;
};

const TabHelp = ({ steps, setShowHelp }: TabHelpProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [direction, setDirection] = useState(0);
  const variants = {
    initial: { opacity: 0 },
    enter: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const nextStep = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (currentStep < steps.length - 1) {
      setDirection(() => 1);
      setCurrentStep((prev) => prev + 1);
    }
  };

  const prevStep = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (currentStep > 0) {
      setDirection(() => -1);
      setCurrentStep((prev) => prev - 1);
    }
  };

  return (
    <motion.div
      layout
      onClick={() => setShowHelp(false)}
      className='text-d-text-negative pointer-events-none relative -top-2 mb-5 flex w-full items-center justify-between gap-2 overflow-hidden rounded-xl bg-d-surface-bg-secondary p-4'>
      {/* PREVIOUS AND NEXT BUTTON */}
      <div className='pointer-events-auto flex flex-grow items-center justify-between gap-2 text-sm'>
        <button
          onClick={prevStep}
          className={currentStep === 0 ? 'text-text-color-muted' : ''}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>

        {/* CONTENT */}
        <div className='flex items-center overflow-hidden text-xs text-center'>
          <AnimatePresence mode='wait'>
            <motion.div
              key={currentStep}
              custom={direction}
              variants={variants}
              initial='initial'
              animate='enter'
              exit='exit'
              transition={{ duration: 0.2 }}>
              <div className='flex items-center'>{steps[currentStep]}</div>
            </motion.div>
          </AnimatePresence>
        </div>

        <button
          onClick={nextStep}
          className={
            currentStep === steps.length - 1 ? 'text-text-color-muted' : ''
          }>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </motion.div>
  );
};

export default TabHelp;
