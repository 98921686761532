import { Dispatch, memo, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { BeinkPhase } from '../../../types';
import {
  faWandMagicSparkles,
  faImage,
  faCircleQuestion,
  faHouse,
  faUpRightAndDownLeftFromCenter,
  faDownLeftAndUpRightToCenter,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const PanelNavigation = memo(function ({
  clickAction,
  currentPhase,
  isPanelOpen,
  setIsPanelOpen,
}: {
  clickAction: Dispatch<SetStateAction<BeinkPhase>>;
  currentPhase: number;
  isPanelOpen: boolean;
  setIsPanelOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation(['translation', 'fnac', 'panel']);
  const navs = [
    {
      name: t('fnac:Panel.Generate'),
      phase: BeinkPhase.Generate,

      icon: faWandMagicSparkles,
    },
    {
      name: t('panel:Tabs.results'),
      phase: BeinkPhase.Results,
      icon: faImage,
    },
    {
      name: null,
      phase: BeinkPhase.Help,
      icon: faCircleQuestion,
      styles: 'px-3 py-5 mx-0 flex items-center gap-2',
    },
  ];

  return (
    <div className='relative mb-2 w-full place-self-end'>
      <motion.div
        animate={
          isPanelOpen
            ? { borderRadius: '14px 14px 0 0' }
            : { borderRadius: '14px' }
        }
        transition={{ duration: 0.15 }}
        className={`pointer-events-auto relative z-10 grid items-center gap-3 !rounded-tl-[24px] !rounded-tr-[24px] bg-fnac-dark-gray p-4 pb-4`}>
        <div className='top-menu grid h-full grid-cols-[min-content_1fr_min-content] items-center justify-center'>
          <Link to='/'>
            <FontAwesomeIcon
              icon={faHouse}
              className='rounded-md bg-grey-400 p-3'></FontAwesomeIcon>
          </Link>

          <div className="h-full w-full bg-[url('/v2/beink-logo.svg')] bg-contain bg-center bg-no-repeat"></div>

          <motion.div
            className=''
            whileHover={{ scale: 1.1 }}
            onClick={() => {
              setIsPanelOpen(!isPanelOpen);
            }}>
            {isPanelOpen ? (
              <FontAwesomeIcon
                icon={faDownLeftAndUpRightToCenter}
                className={`cursor-pointer rounded-md bg-fnac-dark-gray p-3 ${isPanelOpen ? 'bg-fnac-mustard' : ''} transition-colors`}
              />
            ) : (
              <FontAwesomeIcon
                icon={faUpRightAndDownLeftFromCenter}
                className={`cursor-pointer rounded-md bg-fnac-dark-gray p-3 ${isPanelOpen ? 'bg-fnac-mustard' : ''} transition-colors`}
              />
            )}
          </motion.div>
        </div>
      </motion.div>

      <motion.div
        animate={
          !isPanelOpen ? { y: '-100%', opacity: 0 } : { y: 0, opacity: 1 }
        }
        transition={{ duration: 0.2 }}
        className={
          'z-0 flex w-full rounded-b-[24px] bg-fnac-dark-gray p-4 pt-0 text-sm'
        }>
        {navs.map(({ name, icon, phase, styles }) => (
          <div
            key={name}
            onClick={() => {
              clickAction(phase);
            }}
            className={`relative h-[52px] flex-grow cursor-pointer justify-center rounded-[14px] text-center ${styles || 'flex items-center gap-2 px-5'}`}>
            {currentPhase === phase && (
              <motion.div
                layoutId='selected'
                transition={{ duration: 0.1 }}
                className='absolute left-0 z-0 h-full w-full rounded-[14px] bg-grey-400'></motion.div>
            )}
            <div className='z-10 flex items-center gap-2'>
              <FontAwesomeIcon icon={icon}></FontAwesomeIcon>
              {name}
            </div>
          </div>
        ))}
      </motion.div>
    </div>
  );
});

export default PanelNavigation;
