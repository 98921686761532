import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export const FreshChallengerHeaderCard: React.FC = () => {
  const { t } = useTranslation(['fnac']);

  return (
    <NavLink to='/creator'>
      <div
        onClick={() => {}}
        className='flex flex-col px-4 py-6 sm:items-start md:w-full md:items-start xl:max-w-screen-2xl'>
        <div className='grid place-items-center justify-items-center gap-4 rounded-lg border border-hidden bg-fnac-radial-gradient p-4 xs:grid-cols-1 sm:w-full sm:grid-cols-2 md:max-w-4xl md:px-8 lg:p-8'>
          <div className='flex w-full flex-col items-center sm:p-6'>
            <h2 className='w-44 text-base uppercase lg:w-64 lg:text-2xl'>
              <span className='font-jakarta font-extrabold'>
                {t('fnac:Contest.Create')}
              </span>{' '}
              <br />
              <span className='font-ccsign font-bold text-fnac-mustard xs:text-sm lg:text-xl'>
                {t('fnac:Contest.FnacCard')}
              </span>
            </h2>
          </div>
          <div className="h-36 w-full bg-[url('/v2/fnac_card_empty.svg')] bg-contain bg-center bg-no-repeat lg:-my-6 lg:h-44"></div>
        </div>
      </div>
    </NavLink>
  );
};
