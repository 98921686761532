
import { useRef, useEffect } from 'react'


type CanvasProps = {
    draw: (context: CanvasRenderingContext2D, mousePosRef: { x: number, y: number }, isMouseDown: boolean, isMouseUp: boolean) => void
    setup: (context: CanvasRenderingContext2D) => void
}

const Canvas = ({ draw, setup }: CanvasProps) => {


    const canvasRef = useRef<HTMLCanvasElement>(null)



    const mousePosRef = useRef({ x: 0, y: 0 })

    const isMouseDown = useRef(false);
    const isMouseUp = useRef(false);


    useEffect(() => {
        if (!canvasRef) return;
        const canvas = canvasRef.current;

        const context = canvas?.getContext('2d');



        let animationFrameId: number;

        if (context)
            setup(context);

        const render = () => {

            if (context)
                draw(context, mousePosRef.current, isMouseDown.current, isMouseUp.current);
            animationFrameId = window.requestAnimationFrame(render);

            isMouseUp.current = false;
            isMouseDown.current = false;
        }
        render();

        return () => {
            window.cancelAnimationFrame(animationFrameId);
        }
    }, [draw])

    return <canvas
        //Mouse events
        onMouseDown={() => {
            isMouseDown.current = true
        }}
        onMouseMove={(event) => {
            mousePosRef.current.x = event.clientX
            mousePosRef.current.y = event.clientY


        }}
        onMouseUp={() => { isMouseUp.current = true }}

        //Touch events
        onTouchStart={(e) => {
            mousePosRef.current.x = e.touches[0].clientX
            mousePosRef.current.y = e.touches[0].clientY
            isMouseDown.current = true
        }}
        onTouchEnd={() => {
            isMouseUp.current = true
            isMouseDown.current = false
        }}
        onTouchMove={(e) => {
            e.preventDefault()
            mousePosRef.current.x = e.touches[0].clientX
            mousePosRef.current.y = e.touches[0].clientY
        }}

        ref={canvasRef} width={1400} height={1000} />
}


export default Canvas;