import { useTranslation } from 'react-i18next';
import useAppContext from '../../../hooks/useAppContext';

const GenerationsLeft = () => {
  const { generationsLeft } = useAppContext();
  const { t } = useTranslation(['panel']);

  return (
    <div className='text-text-color-muted w-full text-nowrap text-center text-xs font-light opacity-70'>
      {t('panel:generations', {
        postProcess: 'interval',
        count: generationsLeft,
      })}
    </div>
  );
};

export default GenerationsLeft;
