type StampShapeProps = {
  label?: string;
};

export const LittleStampShape: React.FC<StampShapeProps> = ({
  label,
}: StampShapeProps) => {
  return (
    <div className="relative font-jakarta font-extrabold text-center text-white text-xs bg-no-repeat bg-cover z-0 p-2 w-28 uppercase">
      <div className="z-10 relative ">{label}</div>

      <div className="absolute z-1 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 scale-[190%]">
        <img src="v2/stamp-shape.svg " alt="" />
      </div>

    </div>
  );
};
