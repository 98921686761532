import React from "react";
import { ShareBox } from "../social/ShareBox";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

interface ModalProps {
  isOpen: boolean;
  img: string;
  setIsThankModalOpen: (isOpen: boolean) => void;
}

export const ThankModal: React.FC<ModalProps> = ({ isOpen, img, setIsThankModalOpen }) => {
  const navigate = useNavigate();
  if (!isOpen) return null;

  return (
    <div
      className={`z-100 border border-hidden fixed inset-0 bg-fnac-gray bg-opacity-80 backdrop-blur-sm flex items-center justify-center p-4 ${isOpen ? "no-doc-scroll" : ""
        }`}
    >
      <div className="beink-radial-gradient rounded-3xl max-w-3xl w-full py-8 sm:py-16 px-6 sm:px-24">
        <div className="flex flex-col gap-4 items-center text-center">
          <span className="font-black font-jakarta text-white text-lg md:text-2xl ">MERCI POUR VOTRE PARTICIPATION</span>
          <p className="text-white text-sm sm:text-base">
            Merci pour votre participation ! Votre création est visible dans la galerie, où vous pouvez voter pour vos visuels favoris.
          </p>

          <div className="flex flex-col md:flex-row gap-4 sm:gap-8 items-center max-w-64 sm:max-w-none sm:my-7 ">

            <img
              className="w-full rounded-lg"
              src={img}
              crossOrigin="anonymous"
            />

            <ShareBox imageSrc={img} />
          </div>

          <div className="flex gap-3 sm:gap-10">
            <button
              onClick={() => { setIsThankModalOpen(false) }}
              className="font-bold font-jakarta bg-transparent border border-white text-white py-4 px-8 rounded-xl hover:scale-[102%] transition-transform duration-75"
            >
              <FontAwesomeIcon className="mr-2" icon={faArrowLeft}></FontAwesomeIcon>

            </button>
            <button
              onClick={() => { navigate("/gallery") }}
              className="font-bold font-jakarta  text-fnac-black bg-fnac-mustard py-2 sm:py-4 px-8 rounded-xl  items-center gap-2 hover:scale-[102%] transition-transform duration-75"
            >


              Aller à la galerie

              <FontAwesomeIcon className="ml-2" icon={faArrowRight}></FontAwesomeIcon>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
