import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react"
import timeStampParam from "../../helpers/api";

import Card from "../components/gallery/Card";

import { CardType } from "../components/gallery/Gallery";

const FnacEnd = () => {
    const { keycloak } = useKeycloak();

    const [winningGenerations, setWinningGenerations] = useState([]);
    const [error, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const getData = async () => {
        try {
            setIsLoading(true);

            const res = await fetch(`${import.meta.env.VITE_API_URL
                }/event/fnac70/submissions${timeStampParam}&page=1&order=desc&sorter=like&mode=normal`,
                { headers: { authorization: `Bearer ${keycloak.token}` } })
            const data = await res.json();
            if (data.error) {
            
                setIsError(true);
            }
            setWinningGenerations(data.submissions.slice(0, 10));
        }
        catch (error) {
      
            setIsError(true);
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getData();
    }, [keycloak])

    return (
        <div className="bg-fnac-black  overflow-hidden text-white  h-full">
            <div className="h-full overflow-auto">
                {/* BANNER */}
                <div className="text-center text-2xl flex flex-col gap-2 p-4 sm:p-8">
                    <div className="uppercase font-jakarta font-extrabold text white">Le jeu concours 70 ans est terminé</div>
                    <div className="font-ccsign uppercase text-fnac-mustard mb-3">Merci d'avoir participé !</div>
                    <div className=" text-white text-sm font-thin">Voici les dix créations qui ont rencontré le plus de succès</div>
                </div>

                {/* CARD GRID */}
                <div className="h-full">
                    {isLoading && <div className="text-sm text-fnac-mustard">Chargement des créations gagnantes</div>}
                    {error && <div className="text-sm text-fnac-mustard">Une erreur est survenue</div>}
                    <div className=" grid w-full gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 p-3 sm:p-10">
                        {winningGenerations.map((generation: CardType, i) => (
                            <Card key={i} card={generation} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FnacEnd