import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { GenerateImage } from '../../../../hooks/useGenerate';
import { DesignStyle } from '../../../../types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowPointer,
  faMagicWandSparkles,
} from '@fortawesome/free-solid-svg-icons';
import AttemptsCounter from './AttemptsCounter';

export const ImagineFnac = function ImagineFnac({
  graphicStyle,
  setGraphicStyle,
  input,
  setInput,
  onGetSelection,
  generateImage,
  isEnabled,
  error,
  generationsLeft,
}: {
  graphicStyle: DesignStyle;
  setGraphicStyle: Dispatch<SetStateAction<DesignStyle>>;
  input: string;
  setInput: Dispatch<SetStateAction<string>>;
  onGetSelection: () => Promise<string>;
  generateImage: GenerateImage;
  isEnabled: boolean;
  error: string | null;
  generationsLeft: number;
}) {
  const { t } = useTranslation(['panel']);

  const SUGGESTIONS: { [k: string]: string } = {
    [DesignStyle.Industrial]: t('DesignStyle.Industrial'),
    [DesignStyle.Line]: t('DesignStyle.Line'),
    [DesignStyle.Davinci]: t('DesignStyle.Davinci'),
    [DesignStyle.HandSketch]: t('DesignStyle.HandSketch'),
    [DesignStyle.FashionDrawing]: t('DesignStyle.FashionDrawing'),
    [DesignStyle.Scientific]: t('DesignStyle.Scientific'),
    [DesignStyle.Photorealistic]: t('DesignStyle.Photorealistic'),
  };

  const handleGenerate = async () => {
    const dataImg = await onGetSelection();
    if (dataImg && input && graphicStyle)
      await generateImage({ input, graphicStyle, dataImg });
  };

  const numberClasses =
    ' bg-fnac-mustard h-[42px] w-[42px] min-w-[32px] flex items-center font-ccsign justify-center rounded-full text-fnac-black text-xl';

  let buttonContent = (
    <>
      Donner vie à mon idée
      <FontAwesomeIcon icon={faMagicWandSparkles}></FontAwesomeIcon>
    </>
  );

  if (!input) {
    buttonContent = (
      <>
        Aucun texte renseigné
        <FontAwesomeIcon icon={faArrowPointer}></FontAwesomeIcon>
      </>
    );
  }

  if (!isEnabled) {
    buttonContent = (
      <>
        Aucun dessin sélectionné
        <FontAwesomeIcon icon={faArrowPointer}></FontAwesomeIcon>
      </>
    );
  }
  if (generationsLeft <= 0) {
    buttonContent = <>{t('panel:Error.noAttemptLeftButton')}</>;
  }
  return (
    <div className='prompt-form font-si flex h-full w-full flex-col items-center justify-between gap-8 rounded-[32px] bg-fnac-dark-gray p-5 text-[14px]'>
      <div className='flex w-full flex-col gap-4 overflow-auto'>
        <div className='flex flex-col items-center gap-4 rounded-2xl bg-grey-400 p-8 text-center font-ccsign'>
          <div className='flex flex-col items-center gap-2 text-center'>
            <span className={numberClasses}> 1</span>

            <span className='block max-w-[200px] text-base'>
              Je dessine sur le tableau ce que m'évoque la Fnac
            </span>
          </div>

          <div className='mw-200 flex flex-col items-center justify-center gap-4'>
            <img src='v2/outil_dessin.png' alt='' className='block w-2/3' />
            <span className='block max-w-[200px] items-center text-center font-jakarta text-base font-thin'>
              Mon visuel sera incrusté sur la carte Fnac+ !
            </span>
          </div>
        </div>

        <div className='flex flex-col gap-8 rounded-2xl bg-grey-400 p-6'>
          <div className='flex flex-row items-center justify-center gap-2 font-ccsign'>
            <span className={numberClasses}>2</span>
            <span className='max-w-[200px] text-center text-base'>
              Je décris ici mon dessin et choisis mon style visuel
            </span>
            <img src={'v2/arrow.svg'} alt='' />
          </div>
          <textarea
            id='prompt'
            className='w-full rounded-2xl !p-4 pb-0'
            rows={4}
            placeholder={t('panel:Generate.promptPlaceholder')}
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          <select
            id='graphic-style'
            className='h-12 rounded-2xl p-4 text-base'
            value={graphicStyle}
            onChange={(e) => {
              setGraphicStyle(e.target.value as DesignStyle);
            }}>
            {Object.entries(SUGGESTIONS).map((key) => (
              <option value={key[0]} key={key[0]}>
                {key[1]}
              </option>
            ))}
          </select>
        </div>

        <div className='flex w-full flex-col items-center gap-6 rounded-2xl bg-grey-400 p-6 font-jakarta'>
          <div className='flex w-full items-center gap-3 sm:flex-col'>
            <div className='flex w-full flex-col items-center gap-2'>
              <span className={numberClasses}>3</span>
              <span className='max-w-[200px] text-center font-ccsign text-base'>
                Je sélectionne mon dessin et je génère l’image
              </span>
            </div>
            <img
              src='v2/outil_selection.png'
              className='hidden w-1/2 sm:block'
              alt=''
            />
          </div>
        </div>
      </div>
      <div className='flex w-full flex-col gap-4 border-t-[1px] border-grey-400 pt-4'>
        <AttemptsCounter
          className={'self-center'}
          attemptsCount={generationsLeft}
        />

        {/* GENERATE button */}
        <button
          className={`mt-2 flex w-full items-center justify-center gap-2 self-end rounded-2xl !p-4 font-jakarta !text-base !font-extrabold ${isEnabled && input ? '!bg-fnac-mustard' : 'bg-grey-400'}`}
          onClick={handleGenerate}
          disabled={!isEnabled || !input || generationsLeft <= 0}>
          {buttonContent}
        </button>

        {error && (
          <div
            className='alert-danger'
            role='alert'
            dangerouslySetInnerHTML={{ __html: error || '' }}></div>
        )}
      </div>
    </div>
  );
};
