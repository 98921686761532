import { AnimatePresence, } from "framer-motion";
import { Flag, Heart, Info } from "lucide-react";
import InfoPanel from "./InfoPanel";
import { useState } from "react";
import { CardType } from "./Gallery";
import { formatNumberToK } from "../../../helpers/parsers";



export type CardProps = {
  card: CardType;
  isVoteDisabled?: boolean;
  isFnacModerator?: boolean;
  onLike?: (id: string) => void;
  onFlag?: (id: string) => void;
  onShareHandler?: (e: React.MouseEvent) => void;
};

const Card = (props: CardProps) => {
  const { card, isVoteDisabled = false, onLike, onFlag } = props;
  const { id, src, likes, hasMyVote } = card;
  const [showInfoPanel, setShowInfoPanel] = useState(false);
  return (
    <div className="flex flex-col w-full p-4 border border-hidden rounded-2xl bg-fnac-card-default relative">
      <AnimatePresence>
        {showInfoPanel &&
          <InfoPanel setShowInfoPanel={setShowInfoPanel}
            {...props} card={card}></InfoPanel>
        }
      </AnimatePresence>

      <div className="relative">
        <img
          src={src}
          className="w-full aspect-[16/10] object-cover rounded-lg mb-2 border-r border-fnac-black"
        />
      </div>

      <div className="flex justify-between items-center">
        <div className="flex space-x-2 items-center">
          {isVoteDisabled || !onLike ? (
            <div>
              <Heart width={18} height={18} />
            </div>
          ) : (
            <button onClick={(event) => {
              event.preventDefault();

              onLike(id);
            }} value={id}>
              <Heart
                width={18}
                height={18}
                color={hasMyVote ? "#E9AA00" : "#fff"}
              />
            </button>
          )}
          <span className="font-jakarta font-bold text-sm">{formatNumberToK(likes)}</span>
        </div>
        <div className="flex flex-col space-y-4 justify-between items-end">
          <div className="flex space-x-2 py-2">
            {
              onFlag && (
                <button onClick={(e) => {
                  e.preventDefault();
                  onFlag(id);
                }} value={id}>
                  <Flag width={16} height={16} />
                </button>)
            }

            <button onClick={() => {
              setShowInfoPanel(true);
            }} value={id}>
              <Info width={16} height={16} />
            </button>
          </div>
        </div>
      </div>
    </div >

  );
};

export default Card;
