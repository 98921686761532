import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';

import enTranslation from './locales/en/translation.json';
import frTranslation from './locales/fr/translation.json';

import enFnac from './locales/en/fnac.json';
import frFnac from './locales/fr/fnac.json';

import enPanel from './locales/en/panel.json';
import frPanel from './locales/fr/panel.json';

//TODO: when fnac is over, delete old translations
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    resources: {
      fr: {
        translation: frTranslation,
        fnac: frFnac,
        panel: frPanel,
      },
      en: {
        translation: enTranslation,
        fnac: enFnac,
        panel: enPanel,
      },
    },
    defaultNS: 'translation',
    fallbackNS: 'translation',
    fallbackLng: 'fr', // Use French as fallback
    supportedLngs: ['fr', 'en'],
    interpolation: { escapeValue: false },
  });

export default i18n;
