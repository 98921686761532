import { useContext } from 'react';
import { PanelInputContext } from './PanelInputContext';

export const usePanelInputContext = () => {
  const context = useContext(PanelInputContext);
  if (context === undefined) {
    throw new Error('usePanelInputContext must be used within a PanelInputProvider');
  }
  return context;
};
