import { persistentAtom } from "@nanostores/persistent";

export const $excalidrawElements = persistentAtom<string>(
  "excalidraw-elements",
  ""
);

export const $excalidrawState = persistentAtom<string>(
  "excalidraw-state",
  ""
);