type Vector = { x: number, y: number };

export const dot = (v1: Vector, v2: Vector) => {
    return v1.x * v2.x + v1.y * v2.y;
}
export const subtract = (v1: Vector, v2: Vector) => {
    return { x: v1.x - v2.x, y: v1.y - v2.y };
}
export const normalize = (v: Vector) => {
    const length = Math.sqrt(v.x * v.x + v.y * v.y);
    return { x: v.x / length, y: v.y / length };
}
export const length = (v: Vector) => {
    return Math.sqrt(v.x * v.x + v.y * v.y);
}
