import { useTranslation } from 'react-i18next';
import { ShareBox } from '../social/ShareBox';
import { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

export const ChallengerHeaderCard: React.FC = () => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation(["fnac"]);

  const [img, setImg] = useState<string>('/v2/my-card.png');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!keycloak.token) return;

    fetch(new URL('/event/fnac70/submission', import.meta.env.VITE_API_URL), {
      headers: { authorization: `Bearer ${keycloak.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) return;

        setImg(data.url);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [keycloak]);

  if (loading) return;

  return (
    <div className='beink-radial-gradient flex max-w-[980px] flex-col items-center justify-between justify-items-center gap-6 rounded-3xl p-4 md:p-8 lg:flex-row'>
      <div className='flex flex-col justify-center text-center text-xl uppercase'>
        <h1 className='font-jakarta font-black text-white md:min-w-[190px] lg:text-left'>
          {t('fnac:MyContest.Entry')}
        </h1>
        <p className='self-center text-center font-ccsign font-bold text-fnac-mustard md:mx-auto md:max-w-[160px] md:self-start lg:max-w-[190px] lg:text-left'>
          {t('fnac:MyContest.Fnac70')}
        </p>
      </div>
      <div className='flex w-full flex-col gap-4 sm:flex-row'>
        <div className='w-full self-center'>
          <img
            className='m-auto max-h-40 rounded-xl md:h-40 md:min-h-40 md:w-64 md:min-w-64 md:max-w-64'
            src={img}
            crossOrigin='anonymous'></img>
        </div>
      </div>
      <ShareBox imageSrc={img} />
    </div>
  );
};
