import { useKeycloak } from "@react-keycloak/web";

export const FnacLanding: React.FC = () => {
  const { keycloak } = useKeycloak();

  const handleLoginRedirect = () => {
    return keycloak.login({
      idpHint: import.meta.env.VITE_FNAC_SSO_IDP_HINT,
      redirectUri: import.meta.env.VITE_FNAC_SSO_REDIRECT_URI,
      locale: 'fr', // Set locale to French
    });
  };

  return (
    <div className="grid  h-full w-full text-fnac-black justify-center items-center p-4">
      <div className="flex  w-full max-w-96 py-8 3xl:scale-125 4xl:scale-150 px-6 relative overflow-hidden bg-white rounded-2xl gap-5 bg-[url(/v2/pattern_dark.png)] bg-cover ">
        <div className="z-10 gap-6 flex flex-col">
          <div className="flex flex-col text-3xl uppercase text-center">
            <p className="font-extrabold ">Participer au</p>
            <p className=" font-extrabold">Concours</p>
          </div>
          <p className="text-center mx-4 text-fnac-black">
            Vous pouvez utiliser vos identifiants Fnac pour créer la carte Fnac+ édition spéciale 70 ans !
          </p>
          <button
            className="flex w-full justify-center items-center text-center bg-fnac-mustard  font-jakarta font-semibold py-4 rounded-2xl"
            onClick={handleLoginRedirect}
          >
            <span className="text-fnac-black text-[16px] font-jakarta px-2">
              M’identifier sur Fnac.com
            </span>
          </button>
        </div>
        <div className="bg-overlay absolute top-0 left-0 w-full h-full bg-white z-0 opacity-70"></div>
      </div>


    </div>
  );
};
