import { useContext } from 'react';
import { PanelContext } from './PanelContext';

export const usePanelContext = () => {
  const context = useContext(PanelContext);
  if (context === undefined) {
    throw new Error('usePanelContext must be used within a PanelContext');
  }
  return context;
};
