import { FnacClaim } from "../components/auth/FnacClaim";
import { FnacLanding } from "../components/auth/FnacLanding";

export const WelcomePage: React.FC = () => {
  return (
    <div className="grid relative grid-cols-1 grid-rows-[min-content_1fr] items-center md:grid-cols-2 md:grid-rows-1 h-dvh justify-between w-dvw  bg-fnac-black">
      <FnacClaim />
      <FnacLanding />
    </div>
  );
};
