
import { Generation } from '../types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'

import GenerationInfoPanel from './GenerationInfoPanel'
import { AnimatePresence } from 'framer-motion'
import { dateToXTimeAgoFormat } from '../helpers/parsers'
import GenerationImage from './GenerationImage'


type GenerationCardProps = {
    generation: Generation,
    isFnacUserOrDemo?: boolean
}

const GenerationCard = ({ generation, isFnacUserOrDemo }: GenerationCardProps) => {
    const [isInfoPanelOpen, setIsInfoPanelOpen] = useState(false);
    const { generatedImagesUrl, createdAt } = generation;


    const timeSinceCreationString = dateToXTimeAgoFormat(createdAt);

    return (
        <div className="flex flex-col gap-3 overflow-hidden rounded-xl bg-fnac-gray p-4 font-jakarta text-sm font-bold">
            <div className="w-full aspect-[16/11]">
                { isFnacUserOrDemo ? // XXX temporary: Fnac event only
                    <img className="rounded-lg object-cover w-full h-full" src={generatedImagesUrl[0]} alt="" />
                    :
                    <GenerationImage imagePath={generatedImagesUrl[0]} className="rounded-lg object-cover w-full h-full" alt="" />
                }
            </div>
            <div className="flex items-center justify-between">

                <div className="flex items-center gap-2">
                    <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
                    <p className="text-sm text-white">{timeSinceCreationString}</p>
                </div>
                <FontAwesomeIcon icon={faCircleInfo} className='cursor-pointer duration-100 hover:scale-[1.2]' onClick={() => {
                    setIsInfoPanelOpen(!isInfoPanelOpen)
                }}></FontAwesomeIcon>
            </div>

            <AnimatePresence>
                {isInfoPanelOpen && <GenerationInfoPanel isFnacUserOrDemo={isFnacUserOrDemo} setIsInfoPanelOpen={setIsInfoPanelOpen} generation={generation} timeSinceCreationString={timeSinceCreationString}></GenerationInfoPanel>}
            </AnimatePresence>

        </div>
    )
}

export default GenerationCard