//import useMediaQuery from '@mui/material/useMediaQuery';

//import MobilePanel from './mobile/MobilePanel';
import DesktopPanel from './DesktopPanel';

function Panel() {
  //Detect small screens to render mobile version - based on the excalidraw breakpoint
  //const isMobile = useMediaQuery('(max-width: 729px)');

  //return isMobile ? <MobilePanel /> : <DesktopPanel />;

  return <DesktopPanel />;
}

export default Panel;
