import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faMagicWandSparkles, faMessage, faPencil } from '@fortawesome/free-solid-svg-icons'
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Dispatch, SetStateAction, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion'
import { BeinkPhase } from '../../../../types';

const Help = ({ setPhase }: { setPhase: Dispatch<SetStateAction<BeinkPhase>> }) => {
    const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

    const isASectionActive = !(currentSectionIndex === 0);

    const sections = [
        {
            title: "Comment ça marche ?",
            index: 1
        },
    ];

    const variants = {
        initial: {
            x: 0,
            transition: {
                duration: 0.3,
                ease: "linear"
            }
        },
        animate: {
            x: "-50%",
            transition: {
                duration: 0.3,
                ease: "linear"
            }
        }
    };

    const titleClasses = "title text-sm text-center font-jakarta text-[12px] font-bold flex items-center gap-3";

    return (
        <div className={" w-full h-full  bg-fnac-dark-gray font-si rounded-[32px]  text-[12px] overflow-hidden"}
        >
            <motion.div className=" w-[200%] h-full grid grid-cols-2"
                variants={variants}
                initial="initial"
                animate={isASectionActive ? "animate" : "initial"}>

                {/* Section Menu */}
                <div className="menu w-full h-full flex flex-col justify-between">
                    <div className=" p-5 " >
                        {/* Title */}
                        <div className={titleClasses}>
                            <div className="h-[42px] w-[42px] ">
                                <FontAwesomeIcon className='cursor-pointer bg-grey-400 p-4 rounded-md ' icon={faArrowLeft} onClick={() => setPhase(BeinkPhase.Generate)} />
                            </div>
                            <div className="text-[20px]">
                                Guide d'utilisation
                            </div>
                        </div>
                        <div className="desc my-5 text-sm">Retrouvez ici toute l'aide nécéssaire pour utiliser Beink Dream facilement</div>
                        {/* Menu Nav */}
                        {
                            sections.map(({ title, index }) => {
                                return (
                                    <div key={index} onClick={() => setCurrentSectionIndex(index)} className="cursor-pointer flex items-center justify-between gap-2 p-4 my-2 bg-grey-400 rounded-[12px]">
                                        <div className="">
                                            <FontAwesomeIcon icon={faCircleQuestion} className='mx-2' />
                                            <span className="text-sm py-4">{title}</span>
                                        </div>

                                        <FontAwesomeIcon icon={faArrowRight} />
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div />
                </div>

                {/* Current section */}
                <div className="section p-5 flex flex-col items-center">
                    {/* Section Title */}
                    {currentSectionIndex != 0 &&
                        <>
                            <div className="title pb-4 flex place-self-start items-center gap-3 text-">
                                {/* Back */}
                                <div className="h-[42px] w-[42px]">
                                    <FontAwesomeIcon className='cursor-pointer bg-grey-400 p-4 rounded-md ' icon={faArrowLeft} onClick={() => setCurrentSectionIndex(0)} />
                                </div>
                                <div className={titleClasses}>
                                    <span className="text-xl">{sections[currentSectionIndex - 1].title}</span>
                                </div>
                            </div>
                            {/* Section Content */}
                            {currentSectionIndex === 1 && <HowToUse />}
                        </>
                    }
                </div>

            </motion.div>
        </div >
    )
}

const HowToUse = () => {
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const steps = [
        {
            index: 0,
            title: "Je dessine sur le tableau",
            icon: faPencil,
            image: "v2/tuto-step-1.mp4",
            desc: "Puis j'utilise les outils pour personnaliser mon dessin ou mon image"

        },
        {
            index: 1,
            title: "Je décris mon idée",
            icon: faMessage,
            image: "v2/tuto-step-2.mp4",

            desc: "En une phrase simple et je choisis le style d'image que je veux générer"
        },
        {
            index: 2,
            title: "Je générère mon idée",
            icon: faMagicWandSparkles,
            image: "v2/tuto-step-3.mp4",

            desc: "Je selectionne mon dessin et je clique sur Générer. Je continue d'améliorer mon idée si je le souhaite"
        }
    ]

    const buttonClasses = "!h-[40px] !w-[40px] !flex cursor-pointer !items-center !justify-center !m-0 !rounded-full !bg-white !text-grey-600";


    return (
        <div className="w-full bg-grey-400 rounded-[16px] overflow-hidden p-4">

            {/* Carousel */}
            <AnimatePresence mode="wait">
                <motion.div
                    key={currentStepIndex}
                    initial={{ x: 100, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -100, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className="flex flex-col gap-4 p-4">
                    <div className="title flex flex-col items-center gap-[8px] text-[16px] font-jakarta py-[12px]">
                        <FontAwesomeIcon icon={steps[currentStepIndex].icon} className='scale-150 ' />
                        <span>{steps[currentStepIndex].title}</span>

                    </div>
                    <video className='rounded-[10px]' autoPlay src={steps[currentStepIndex].image} />
                    <div className="desc text-center text-[14px]">{steps[currentStepIndex].desc}</div>
                </motion.div>
            </AnimatePresence>

            {/* Carousel Nav */}
            <div className="flex justify-between p-5 items-center">
                <button className={buttonClasses} onClick={() => setCurrentStepIndex(currentStepIndex - 1)} disabled={currentStepIndex === 0}>
                    <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                </button>
                <div className="flex gap-2 items-center">
                    {
                        steps.map(({ index }) => {
                            return <div key={index} className={currentStepIndex === index ? "rounded-full bg-white h-[4px] w-[4px] " : "rounded-full bg-grey-200 h-[4px] w-[4px] "} />
                        })
                    }
                </div>
                <button className={buttonClasses} onClick={() => setCurrentStepIndex(currentStepIndex + 1)} disabled={currentStepIndex === steps.length - 1}>
                    <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                </button>
            </div>
        </div>

    );
}




export default Help