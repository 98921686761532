export const isTouchpad = (e: WheelEvent): boolean => {
  const testAxe =
    Math.abs(e.deltaY) > Math.abs(e.deltaX)
      ? Math.abs(e.deltaY)
      : Math.abs(e.deltaX);

  return !(testAxe > 80);
};

export const isTouchDevice = (): boolean => {
  return window.matchMedia("(pointer: coarse)").matches;
};

export const isMobile = (): boolean => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  const test = toMatch.some((toMatchItem) =>
    navigator.userAgent.match(toMatchItem),
  );

  return test;
};
