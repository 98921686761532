import { Frown } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
  const { t } = useTranslation(['translation']);
  return (
    <div className='ml-14 mt-20 p-6 md:ml-64 md:p-12'>
      <div className='mt-20 flex flex-col items-center justify-center'>
        <Frown className='mb-3 text-red' />
        <h1>{t('NotFound')}</h1>
      </div>
    </div>
  );
};

export default NotFoundPage;
