import { useEffect } from 'react';
import { BeinkMode, UserRole } from '../../../types';
import { Imagine } from './Generate/Imagine';
import { Inpaint } from './Generate/Inpaint';
import GenerateButton from './GenerateButton';

import PanelHeader from './PanelHeader';
import PanelNavigation from './PanelNavigation';
import { Results } from './Results/Results';
import { useKeycloak } from '@react-keycloak/web';
import { usePanelContext } from './context/usePanelContext';
import GenerationsLeft from './GenerationsLeft';
import SimpleButton from '../../lib/SimpleButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const DesktopPanel = () => {
  const {
    isPanelOpen,
    showAllGenerations,
    setShowAllGenerations,
    currentMode,
    error,
  } = usePanelContext();
  const { keycloak } = useKeycloak();
  const userRoles: string[] = keycloak.realmAccess?.roles || [];
  const inpaintAllowed = userRoles.includes(UserRole.Inpaint);

  // Traduction
  const { t } = useTranslation('panel');

  // Fermer AllGenerations quand le panel principal est fermé
  useEffect(() => {
    if (!isPanelOpen) {
      setShowAllGenerations(false);
    }
  }, [isPanelOpen, setShowAllGenerations]);

  return (
    <div
      className={`pointer-events-auto flex h-full w-full text-white ${
        isPanelOpen
          ? 'flex-col gap-6 p-4 md:max-h-screen md:gap-4'
          : 'w-full flex-row justify-stretch gap-4 p-2 md:max-h-screen md:flex-col md:items-center md:justify-center md:p-0 md:py-2'
      }`}
      style={{ maxHeight: isPanelOpen ? '100%' : '98vh', overflow: 'hidden' }}>
      <PanelHeader visible={true} />

      {/* Masquer la navigation si showAllGenerations est true */}
      {!showAllGenerations && (
        <div
          className={`relative flex flex-grow items-center gap-4 md:flex-col ${
            isPanelOpen
              ? 'flex-col md:flex-col'
              : 'flex h-full flex-row md:items-end md:justify-end'
          }`}
          style={{ maxHeight: '80vh' }}>
          <PanelNavigation />
          {isPanelOpen && (
            <div className='flex h-full w-full flex-grow flex-col justify-between p-0'>
              <div className='w-full'>
                {currentMode === BeinkMode.Imagine && <Imagine />}
                {inpaintAllowed && currentMode === BeinkMode.Inpaint && (
                  <Inpaint />
                )}
              </div>
            </div>
          )}
          {error && isPanelOpen && (
            <div
              className='alert-danger'
              role='alert'
              dangerouslySetInnerHTML={{ __html: error || '' }}></div>
          )}
          {error && !isPanelOpen && (
            <div
              className='alert-danger flex w-full flex-col items-center justify-center gap-4 rounded-2xl p-2'
              role='alert'>
              <FontAwesomeIcon icon={faClose} />
            </div>
          )}
        </div>
      )}

      {/* Conteneur pour les générations et le bouton de fermeture */}
      <div
        className={`flex flex-col items-center ${
          isPanelOpen
            ? 'w-full justify-center'
            : 'w-min items-center justify-center p-2 md:items-end md:justify-end'
        } gap-4 bg-transparent md:p-2`}
        style={isPanelOpen ? { maxHeight: '100%', overflow: 'hidden' } : {}}>
        {isPanelOpen && <GenerationsLeft />}

        {/* Zone défilable pour les résultats, toujours visible quand le panel est ouvert ou sur grand écran */}
        {(isPanelOpen || showAllGenerations || window.innerWidth >= 768) && (
          <div
            className={`w-full flex-grow overflow-y-auto md:h-full ${
              isPanelOpen ? 'md:min-w-[300px]' : ''
            }`}
            style={{ maxHeight: 'calc(100vh - 200px)' }} // Ajustement pour laisser de l'espace pour le bouton
          >
            <Results variant={isPanelOpen ? 'full' : 'reduced'} />
          </div>
        )}

        {/* Affiche GenerateButton uniquement quand showAllGenerations est fermé */}
        {!showAllGenerations && <GenerateButton isPanelOpen={isPanelOpen} />}

        {/* Bouton "Fermer" pour AllGenerations, affiché uniquement si showAllGenerations est true */}
        {showAllGenerations && (
          <div className='flex flex-col items-center justify-center p-2'>
            <SimpleButton
              onClick={() => {
                setShowAllGenerations(false);
              }}
              round
              leftIcon={faChevronDown}
              variant='primary'
              size='xs'
              iconSize='12'
              format='rounded'>
              {t('Results.close')} {/* Appel à la traduction */}
            </SimpleButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default DesktopPanel;
