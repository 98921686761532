import { faFlag } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { CardProps } from './Card';
import { formatDate, formatNumberToK } from '../../../helpers/parsers';


type InfoPanelProps = {
    setShowInfoPanel: (show: boolean) => void;
} & CardProps

const InfoPanel = ({ onLike, onFlag, setShowInfoPanel, isVoteDisabled = false, card, isFnacModerator }: InfoPanelProps) => {
    const { date, id, author, src, hasMyVote, likes } = card;

    return (
        <div className="fixed w-screen h-dvh top-0 right-0 z-20 flex items-center justify-end bg-grey-700 bg-opacity-30 backdrop-blur-md">
            <motion.div className=" w-[450px] h-4/5 bg-grey m-2 md:m-8 flex flex-col justify-between items-center rounded-[18px] p-5"
                initial={{ x: 500 }}
                animate={{ x: 0 }}
                exit={{ x: 500 }}
                transition={{ duration: 0.2 }}
            >
                <div className="w-full flex flex-col items-center gap-2 text-[15px]">

                    {/* SUBMISSION IMAGE */}
                    <div className="aspect-video w-full relative">
                        <img src={src} alt="" className="object-cover w-full h-full rounded-xl border-r border-fnac-black" />

                    </div>
                    <div className="flex justify-between w-full text-[13px] ">
                        <p className="font-ccsign  text-right">{author}</p>
                        <div className="">{formatDate(date)}</div>
                    </div>

                    {/* LIKE BUTTON */}

                    {!isVoteDisabled && onLike && (
                        <div className=" mt-3 like-button hover:scale-[102%] duration-75 font-jakarta cursor-pointer font-bold bg-fnac-mustard p-4 w-full text-center rounded-md flex gap-2 items-center justify-center text-fnac-gray"
                            onClick={(e) => {
                                e.preventDefault();
                                onLike(id);
                            }}>
                            {hasMyVote && <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}

                            {hasMyVote ? "Déjà liké" : "J'aime"}
                        </div>

                    )}

                    {/* VOTES */}
                    <div className="relative font-jakarta font-bold text-[13px] text-center flex w-full justify-between">

                        <FontAwesomeIcon icon={faFlag} className=" p-2  bg-grey-400 w-[12px] h-[10px] invisible"></FontAwesomeIcon>

                        <div >
                            {formatNumberToK(likes)} votes
                        </div>

                        {/* FLAG */}
                        {!isFnacModerator && onFlag &&
                            <FontAwesomeIcon icon={faFlag} className="p-2 cursor-pointer hover:scale-110 bg-grey-400 w-[12px] h-[10px] rounded-md"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onFlag(id);
                                }
                                }
                            ></FontAwesomeIcon>
                        }
                    </div>
                </div>

                {/* EXIT PANEL */}
                <motion.div whileHover={{ rotate: 180 }}
                    initial={{ scale: 0.4 }}
                    animate={{ scale: 1 }}
                    transition={{ delay: 0.2 }}
                    className="cursor-pointer rounded-full h-[44px] w-[44px] bg-white flex items-center justify-center text-fnac-black m-5"
                    onClick={() => { setShowInfoPanel(false) }}>
                    <FontAwesomeIcon className="w-2/5 h-2/5" icon={faX} ></FontAwesomeIcon>
                </motion.div>
            </motion.div>
        </div>
    )
}

export default InfoPanel